<template>
  <div class="user-order">
    <el-card class="box-card">
      <div class="search">
        <div style="flex: 1 1 230px">
          <el-input
            v-model="userOrderForm.content"
            placeholder="请输入订单号或地址"
          ></el-input>
        </div>
        <div style="flex: 1 1 230px">
          <el-select
            v-model="userOrderForm.orderType"
            placeholder="请选择订单类型"
          >
            <el-option label="全部" :value='0'></el-option>
            <el-option label="充值" :value='1'></el-option>
            <el-option label="api" :value="2"></el-option>
            <el-option label="小时代理" :value="3"></el-option>
            <el-option label="包月代理" :value="4"></el-option>
            <el-option label="手动租用" :value="5"></el-option>
            <el-option label="笔数委托" :value="6"></el-option>
            <el-option label="笔数滞留" :value="7"></el-option>
            <el-option label="备用api" :value="8"></el-option>
          </el-select>
        </div>
        <!-- <div style="flex: 1 1 230px">
          <el-select v-model="userOrderForm.resType" placeholder="请选择资源类型">
            <el-option label="能量" value="energy"></el-option>
            <el-option label="带宽" value="bandwidth"></el-option>
          </el-select>
        </div> -->
        <!-- <div style="flex: 1 1 230px">
          <el-select v-model="userOrderForm.rentTime" placeholder="请选择租用时间">
            <el-option label="1小时" value="1h"></el-option>
            <el-option label="1（天）" value="1d"></el-option>
            <el-option label="3（天）" value="3d"></el-option>
            <el-option label="4（天）" value="4d"></el-option>
            <el-option label="5（天）" value="5d"></el-option>
            <el-option label="6（天）" value="6d"></el-option>
            <el-option label="7（天）" value="7d"></el-option>
            <el-option label="8（天）" value="8d"></el-option>
            <el-option label="9（天）" value="9d"></el-option>
            <el-option label="10（天）" value="10d"></el-option>
            <el-option label="11（天）" value="11d"></el-option>
            <el-option label="12（天）" value="12d"></el-option>
            <el-option label="13（天）" value="13d"></el-option>
            <el-option label="14（天）" value="14d"></el-option>
            <el-option label="15（天）" value="15d"></el-option>
            <el-option label="16（天）" value="16d"></el-option>
            <el-option label="17（天）" value="17d"></el-option>
            <el-option label="18（天）" value="18d"></el-option>
            <el-option label="19（天）" value="19d"></el-option>
            <el-option label="20（天）" value="20d"></el-option>
            <el-option label="21（天）" value="21d"></el-option>
            <el-option label="22（天）" value="22d"></el-option>
            <el-option label="23（天）" value="23d"></el-option>
            <el-option label="24（天）" value="24d"></el-option>
            <el-option label="25（天）" value="25d"></el-option>
            <el-option label="26（天）" value="26d"></el-option>
            <el-option label="27（天）" value="27d"></el-option>
            <el-option label="28（天）" value="28d"></el-option>
            <el-option label="29（天）" value="29d"></el-option>
            <el-option label="30（天）" value="30d"></el-option>
          </el-select>
        </div> -->
        <!-- <div style="flex: 1 1 230px">
          <el-select v-model="userOrderForm.orderState" placeholder="请选择订单状态">
            <el-option label="异常" value="exception"></el-option>
            <el-option label="回收异常" value="recyclingException"></el-option>
            <el-option label="已回收" value="recycled"></el-option>
            <el-option label="部分回收" value="partialRecycling"></el-option>
            <el-option label="代理中" value="inProxy"></el-option>
            <el-option label="部分代理" value="partialProxy"></el-option>
            <el-option label="安排中" value="underArrangement"></el-option>
          </el-select>
        </div> -->
        <div style="flex: 1 1 230px">
          <el-date-picker
            v-model="userOrderForm.time"
            type="date"
            format="yyyy-MM-dd"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div style="flex: 1 1 230px; display: flex; align-items: center">
          <el-button
            round
            type="primary"
            @click="search"
            style="width: inherit; flex: 1 1 0"
            >搜索</el-button
          >
          <el-button
            round
            type="warning"
            @click="reset"
            style="width: inherit; flex: 1 1 0"
            >重置</el-button
          >
        </div>
      </div>

      <div v-if="pageSize === 'mini'">
        <div class="order-page-list-item" v-for="(item, index) in tableData" :key="index">
          <div class="card-right-index">
            <div style="padding: 0px 10px">{{ index + 1 }}</div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">订单号</div>
            <div class="row-item-right">
              <el-tag size="mini" type="primary" style="font-weight: 600">{{
                item.orderId
              }}</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">订单类型</div>
            <div class="row-item-right">
              <el-tag size="mini" type="primary" style="font-weight: 600">{{
                item.orderType | fifterType(item.orderType)
              }}</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">租用时间</div>
            <div class="row-item-right">
              <el-tag size="mini" type="primary" style="font-weight: 600">{{
                item.times | fifterTimes(item.times)
              }}</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">完成数量</div>
            <div class="row-item-right">
              <el-tag size="mini" type="warning" style="font-weight: 600"
                >{{ item.energyNum }}</el-tag
              >
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">接收地址</div>
            <div class="row-item-right">
              <i
                @click="copy(item.address)"
                style="cursor: pointer; color: #409eff"
                class="el-icon-document-copy"
              ></i>
              <span
                style="
                  color: #409eff;
                  font-weight: 600;
                  display: inline-block;
                  height: 20px;
                  width: 80px;
                  margin-left: 5px;
                "
                class="ellipsis"
                >{{ item.address }}</span
              >
            </div>
          </div>
          <!-- <div class="list-row-item">
            <div class="row-item-left">资源锁定</div>
            <div class="row-item-right">
              <el-switch
                disabled
                v-model="item.rentTimeLock"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </div>
          </div> -->
          <!-- <div class="list-row-item">
            <div class="row-item-left">资源类型</div>
            <div class="row-item-right">
              <el-tag size="mini" type="danger" style="font-weight: 600">{{
                item.orderType === "ENERGY" ? "能量" : ""
              }}</el-tag>
            </div>
          </div> -->
          
          <div class="list-row-item">
            <div class="row-item-left">单价(sun)</div>
            <div class="row-item-right">
              <span style="color: #409eff; font-weight: 600">{{
                item.sun
              }}</span>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">消费</div>
            <div class="row-item-right">
              <span style="color: #409eff; font-weight: 600">{{
                item.money
              }}</span>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">创建时间</div>
            <div class="row-item-right">
              {{ item.createTime }}
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">回收时间</div>
            <div class="row-item-right">
              {{ item.endTime }}
            </div>
          </div>
          <!-- <div class="list-row-item">
            <div class="row-item-left">订单进度</div>
            <div class="row-item-right"></div>
          </div> -->
          <!-- 步骤 -->
          <!-- <el-steps
            size="mini"
            :active="item.orderState"
            process-status="success"
            finish-status="success"
            style="margin: 5px 0 10px 0"
          >
            <el-step title="安排中"></el-step>
            <el-step title="部分代理"></el-step>
            <el-step title="代理中"></el-step>
            <el-step title="已回收部分"></el-step>
            <el-step title="已回收"></el-step>
          </el-steps> -->

          <div class="list-row-item">
            <div class="row-item-left">操作</div>
            <div class="row-item-right"></div>
             <el-button type="success" size="mini" plain @click="hxDeal(item.tid)">交易哈希</el-button>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <!-- <el-button type="success" size="mini" plain @click="hxDeal(item.tid)">交易哈希</el-button> -->
            <!-- <el-button
              plain
              round
              @click="orderRecord(item)"
              style="
                border-color: #409eff;
                color: #409eff;
                padding: 5px;
                width: calc(50% - 10px);
              "
              >消费记录</el-button
            >
            <el-button
              plain
              round
              @click="agentInfo(item)"
              style="
                border-color: #409eff;
                color: #409eff;
                padding: 5px;
                width: calc(50% - 10px);
              "
              >代理详情</el-button
            >
            <el-button
              plain
              round
              @click="copy(item.content)"
              style="
                border-color: #409eff;
                color: #409eff;
                padding: 5px;
                width: calc(50% - 10px);
                margin: 10px 0 0 0;
              "
              >订单ID</el-button
            >
            <el-button
              plain
              round
              @click="orderRenew(item)"
              style="
                border-color: #409eff;
                color: #409eff;
                padding: 5px;
                width: calc(50% - 10px);
                margin: 10px 0 0 10px;
              "
              >订单续费</el-button
            > -->
          </div>
        </div>
      </div>

      <el-table
        v-else
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="orderId" label="订单号" width="200">
          <template slot-scope="scope">
            <el-tag type="primary" style="font-weight: 600">{{
              scope.row.orderId
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="orderType" label="订单类型" width="100">
          <template slot-scope="scope">
            <el-tag effect="dark" type="primary">{{ scope.row.orderType | fifterType(scope.row.orderType) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="times" label="租用时间" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" type="primary" style="font-weight: 600"
                >{{ scope.row.times | fifterTimes(scope.row.times) }}</el-tag
              >
          </template>
        </el-table-column>
        <el-table-column prop="energyNum" label="完成数量" width="130">
          <template slot-scope="scope">
            <el-tag size="mini" type="warning" style="font-weight: 600"
                >{{ scope.row.energyNum }}</el-tag
              >
          </template>
        </el-table-column>
        <el-table-column prop="address" label="接收地址"></el-table-column>
        <el-table-column prop="sun" label="单价(sun)" width="100"></el-table-column>
        <el-table-column prop="money" label="消费" width="130">
          <template slot-scope="scope">
            {{ scope.row.money + 'TRX' }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="endTime" label="回收时间" width="160"></el-table-column>
        <!-- <el-table-column prop="state" label="订单进度" width="130">
          <template slot-scope="scope">
            <el-tag effect="dark" type="primary">{{ scope.row.state }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="rentTimeLock" label="资源锁定">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.rentTimeLock"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="orderType" label="资源类型">
          <template slot-scope="scope">
            <el-tag type="danger" style="font-weight: 600">{{
              scope.row.orderType === "ENERGY" ? "能量" : ""
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lockTime" label="租用时间">
          <template slot-scope="scope">
            <el-tag type="primary" style="font-weight: 600">{{
              scope.row.lockTime
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="payNums" label="完成数量">
          <template slot-scope="scope">
            <el-tag type="warning" style="font-weight: 600"
              >{{ scope.row.payNums / 10000 }}W</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="receiveAddress" label="接收地址" width="120">
          <template slot-scope="scope">
            <i
              @click="copyReceiveAddress"
              style="cursor: pointer; color: #409eff"
              class="el-icon-document-copy"
            ></i>
            <span
              style="
                color: #409eff;
                font-weight: 600;
                display: inline-block;
                height: 20px;
                width: 80px;
                vertical-align: sub;
                margin-left: 5px;
              "
              class="ellipsis"
              >{{ scope.row.receiveAddress }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="orderMoney" label="消费TRX">
          <template slot-scope="scope">
            <span style="color: #409eff; font-weight: 600">{{
              scope.row.orderMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="创建时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recoveryTime" label="回收时间" width="100">
        </el-table-column> -->
        <el-table-column prop="recoveryTime" width="100" label="操作">
          <template slot-scope="scope">
            <!-- <el-button
              plain
              round
              style="color: #409eff; border-color: #b3d8ff; padding: 7px 15px"
              @click="orderRecord(scope.row)"
              >消费记录</el-button
            >
            <el-button
              plain
              round
              style="color: #409eff; border-color: #b3d8ff; padding: 7px 15px"
              @click="agentDetailHandler(scope.row)"
              >代理详情</el-button
            >
            <el-button
              plain
              round
              style="
                color: #409eff;
                border-color: #b3d8ff;
                padding: 7px 23px;
                margin: 10px 0 0 0;
              "
              @click="copy(scope.row.content)"
              >订单ID</el-button
            >
            <el-button
              plain
              round
              style="color: #409eff; border-color: #b3d8ff; padding: 7px 15px"
              @click="orderRenew(scope.row)"
              >订单续费</el-button
            > -->
             <el-button type="success" size="mini" @click="hxDeal(scope.row.tid)">交易哈希</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </el-card>

    <!-- 消费记录 -->
    <OrderRecordDialog
      :dialogVisible="orderRecordDialogVisible"
      :content="currentcontent"
      :pageSize="pageSize"
      @close="closeDialog"
    ></OrderRecordDialog>

    <!-- 代理详情 -->
    <AgentDetailDialog
      :dialogVisible.sync="agentDetailDialogVisible"
      :agentInfo="agentDetail"
      :pageSize="pageSize"
      @close="closeDialog"
    >
    </AgentDetailDialog>

    <!-- 订单续费 -->
    <OrderRenewDialog
      :dialogVisible.sync="orderRenewDialogVisible"
      :agentInfo="agentDetail"
      :pageSize="pageSize"
      @close="closeDialog"
    >
    </OrderRenewDialog>
  </div>
</template>

<script>
import OrderRecordDialog from "./userOrder/order-record-dialog.vue";
import AgentDetailDialog from "./userOrder/agent-detail-dialog.vue";
import OrderRenewDialog from "./userOrder/order-renew-dialog.vue";
import { energyRecord } from "@/api/login";
export default {
  name: "UserOrderView",
  props: {
    pageSize: {
      type: String,
      default: "medium",
    },
  },
  components: { OrderRecordDialog, AgentDetailDialog, OrderRenewDialog },
  data() {
    return {
      loading: false,
      userOrderForm: {
        // content: "",
        // resType: "",
        // rentTime: "",
        // orderState: "",
        // time: "",
        content: "",
        orderType: 0,
        time: ""
      },
      tableData: [],
      // 当前操作订单id
      currentcontent: "",
      // 消费记录dialog
      orderRecordDialogVisible: false,
      // 代理详情dialog
      agentDetailDialogVisible: false,
      agentDetail: {},
      // 订单续费dialog
      orderRenewDialogVisible: false,
      orderRenewForm: {
        days: 3,
        payNums: 0,
        startTime: "",
        endTime: "",
      },
      getDataInterval: null,
      pagination: {
        currentPage: 1, //页码
        pageSize: 10,
        total: 0,
      },
    };
  },
  filters: {
    fifterType(val) {
      let value = "充值";
      switch(val) {
        case 1: value = "充值"; break;
        case 2: value = "api"; break;
        case 3: value = "小时代理"; break;
        case 4: value = "包月代理"; break;
        case 5: value = "手动租用"; break;
        case 6: value = "笔数委托"; break;
        case 7: value = "笔数滞留"; break;
        case 8: value = "备用api"; break;
      }
      return value;
    },
    fifterTimes(val) {
      let value = "不限时";
      switch(val) {
        case 0: value = "不限时"; break;
        case 5: value = "5分钟"; break;
        case 60: value = "1小时"; break;
      }
      return value;
    }
  },
  methods: {
    //交易哈希
    hxDeal(id) {
      window.open("https://tronscan.org/#/transaction/" + id);
    },
    // 搜索数据
    search() {
      // console.log("search");
      this.getTableData();
    },
    // 重置数据
    reset() {
      // console.log("reset");
      this.userOrderForm = {
        content: "",
        resType: "0",
        rentTime: "",
        orderState: "",
        time: "",
      };
      this.getTableData();
    },
    // 获取页面表格数据
    getTableData() {
      this.loading = true;
      let { pageSize, currentPage } = this.pagination;
      let { content,orderType,time } = this.userOrderForm;
      var data = {
        pageSize: currentPage,
        limit: pageSize,
        content,
        orderType,
        time
      };
      // console.log(data);
      energyRecord(data).then((res) => {
        let { code, data } = res.data;
        if (code == 200) {
          // this.tableData = [
          //   {
          //     orderId: '123'
          //   }
          // ]
          this.tableData = data.list;
          this.pagination.total = data.total;
        }
      });
      // todo 从api请求数据
      // this.tableData =[
      //   {
      //     "content": "1709818126263879",
      //     "orderType": "ENERGY",
      //     "rentTimeLock": 0,
      //     orderState: 2,
      //     "hash": [
      //       {
      //         "state": "订单正常",
      //         "content": "711c4542e9ebd484",
      //         "resType": "ENERGY",
      //         "rentTime": "1天",
      //         "resNums": 32000,
      //         "recoveryTime": "2024-03-08 21:28:47",
      //         "hash": "1fc11f66941bec264a6cf8d14990ac619d5c3bcb61d2ff157424b8912893451a",
      //         "sendAddress": "TSTvgxUdRNHNemHfMhL6tKgK1UJqg7VMLW",
      //         "sendResourceResult": 1,
      //         "recoveryHash": "",
      //         "info": "代理成功"
      //       }
      //     ],
      //     "recoveryTime": "2024-03-08 21:28:47",
      //     "state": "代理中",
      //     "canRenewals": true,
      //     "payNums": 32000,
      //     "price": "",
      //     "orderMoney": "2.56 TRX",
      //     "lockTime": "1天",
      //     "time": "2024-03-07 21:28:46",
      //     "orderNotes": "",
      //     "transactionId": "",
      //     "receiveAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm"
      //   },
      //   {
      //     "content": "1709451153863654",
      //     "orderType": "ENERGY",
      //     "rentTimeLock": 0,
      //     orderState: 1,
      //     "hash": [
      //       {
      //         "state": "订单正常",
      //         "content": "f1a7fc65b3dc732d",
      //         "resType": "ENERGY",
      //         "rentTime": " 1时",
      //         "resNums": 32000,
      //         "recoveryTime": "2024-03-03 16:32:34",
      //         "hash": "ae5cd506527bdf1aa78dbc09dc9f9431758392b231edb206f6034e0bc417b659",
      //         "sendAddress": "TD7beBofzDoDZ7qcGUAeHK1zf2Fnsvz2SP",
      //         "sendResourceResult": 1,
      //         "recoveryHash": "8c1acef6608a27e041f73e1264ddc8a0254741696cf9ed2d3af6409cb3d4e012",
      //         "info": "回收成功"
      //       }
      //     ],
      //     "recoveryTime": "2024-03-03 16:32:34",
      //     "state": "已回收",
      //     "canRenewals": false,
      //     "payNums": 32000,
      //     "price": "",
      //     "orderMoney": "1.5 TRX",
      //     "lockTime": " 1时",
      //     "time": "2024-03-03 15:32:33",
      //     "orderNotes": "",
      //     "transactionId": "",
      //     "receiveAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm"
      //   }
      // ]
      this.loading = false;
    },
    // 复制接收地址
    copyReceiveAddress(receiveAddress) {
      const input = document.createElement("input"); // 创建输入框元素
      input.value = receiveAddress; // 设置输入框的值为要复制的内容
      document.body.appendChild(input); // 将输入框添加到页面中
      input.select(); // 选中输入框中的内容
      if (document.execCommand("copy")) {
        // 如果复制成功
        this.$message({
          message: "复制成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "复制失败",
          type: "error",
        });
      }
      document.body.removeChild(input); // 移除输入框
    },
    // 消费记录
    orderRecord(row) {
      const { content } = row;
      this.currentcontent = content;
      this.orderRecordDialogVisible = true;
    },
    // 代理详情
    agentDetailHandler(row) {
      this.agentDetailDialogVisible = true;
      this.agentDetail = row;
    },
    // 订单续费
    orderRenew(row) {
      this.orderRenewDialogVisible = true;
      this.agentDetail = row;
    },
    // 关闭弹窗
    closeDialog() {
      this.orderRecordDialogVisible = false;
      this.agentDetailDialogVisible = false;
      this.orderRenewDialogVisible = false;
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getTableData();
      // console.log(`当前页: ${val}`);
    },
  },
  mounted() {
    this.getTableData();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.user-order {
  margin: 15px;
  text-align: left;
  font-size: 14px;

  .search {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: space-around;

    .el-input,
    .el-select {
      width: 100%;
    }
  }
  ::v-deep .el-pagination {
    margin-top: 10px;
    text-align: end;
  }
}
</style>