<template>
  <el-dialog
    :title="title"
    :visible.sync="transferRentDialogVisible"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :before-close="close"
    custom-class="transfer-rent-form-dialog"
    :append-to-body="true">
    <TransferForm :initForm="transferForm" ref="transferForm" @close="close"></TransferForm>
  </el-dialog>
</template>
  
<script>
import TransferForm from './form'
export default {
  name: 'TransferFormEditDialog',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogForm: {
      type: Object,
      default: ()=>{return {
        orderId: ''
      }}
    },
    title: {
      type: String,
      default: '新增'
    }
  },
  components: { TransferForm },
  data() {
    return {
      transferRentDialogVisible: false,
      transferForm: {}
    }
  },
  watch: {
    dialogVisible: {
      handler: function(newVal) {
        this.transferRentDialogVisible = newVal
        if(newVal) {
          this.transferForm = JSON.parse(JSON.stringify(this.dialogForm))
        } else {
          this.$refs.transferForm.close()
        }
      }
    }
  },
  methods: {
    close() {
      // this.$refs.transferForm.close();
      this.$emit('close')
      this.transferRentDialogVisible = false;
    }
  }
}
</script>
    