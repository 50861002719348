const CryptoJs = require("crypto-js"); // 引用AES源码js

const keyStr = 'TV6J17i6DSWUypLdad12vq1Bwf2JTDADADEr';

export const encryptByAES = function(data) {
  // 16位，密钥字符串
  const key = CryptoJs.enc.Utf8.parse(keyStr);
  const mydata = CryptoJs.enc.Utf8.parse(data);
  const udata = CryptoJs.AES.encrypt(mydata, key, {
    mode: CryptoJs.mode.ECB, // 加密模式，ECB模式
    padding: CryptoJs.pad.Pkcs7, // 填充方式
  });
  const encrypted = udata.toString()

  return encrypted
}

const encode = function(string) {
  // const Buffer = require('buffer').Buffer;
  // return Buffer.from(string).toString('base64')
  const str = JSON.stringify(string);
 
  // 将编码后的字符串转换成实际的base64编码
  let base64Encoded = btoa(str);

  return base64Encoded;
}

export const encryptCode = function(obj){
  const urlString = encode(obj);
  return Math.floor(Math.random() * 10)+encryptByAES(urlString)
}


  
  // const bytes  = CryptoJs.AES.decrypt(udata, keyStr, {
  //   mode: CryptoJs.mode.ECB, // 加密模式，ECB模式
  //   padding: CryptoJs.pad.Pkcs7, // 填充方式
  // });
  // const originalText = bytes.toString(CryptoJs.enc.Utf8)
  // console.log(originalText)