<template>
  <div class="con">
    <div class="imgBox">
      <img src="@/assets/img/announcement.png" />
    </div>
    <div class="scroll-container" id="carousel">
      <div class="scroll-item">{{ values }}</div>
    </div>
  </div>
</template>

<script>
import { notice } from "@/api/login";
export default {
  data() {
    return {
      values: null,
      currentIndex: 0,
      currentItemIndex: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      notice().then((res) => {
        let { code, data } = res.data;
        if (code == 200) {
          this.values = data.noticeData.content;
          //   this.updateCarousel(this.values);
        }
      });
    },
    updateCarousel(text) {
      const carouselContainer = document.getElementById("carousel");
      const scrollItem = document.createElement("div");
      scrollItem.classList.add("scroll-item");
      scrollItem.textContent = text;
      carouselContainer.appendChild(scrollItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.con {
  width: 100%;
  background: #fffbe8;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .imgBox {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 16px;
    width: 16px;
  }
}
.scroll-container {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  position: relative;
}
.scroll-item {
  color: #ed6a0c;
  font-size: 17px;
  width: 100%;
  display: inline-block;
  animation: scroll 25s linear infinite; /* 滚动动画，持续时间10秒，线性运动，无限循环 */
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-20%);
  }
}
</style>