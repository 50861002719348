<template>
<!-- 二维码 -->
  <div id="qrcode"></div>
</template>

<script>
export default {
  props: {
    qrCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      qrcode: null
    };
  },
  watch: {
    qrCode() {
        this.qrcodeA();
    },
  },
  mounted() {
    this.qrcodeA();
  },
  methods: {
    qrcodeA() {
      // 获取要显示二维码的 DOM 元素
      var qrcodeContainer = document.getElementById("qrcode");
      if(this.qrcode) {
        this.qrcode.clear();
        qrcodeContainer.innerHTML = "";
      } 
      var _this = this;
      
      // 实例化一个 QRCode 对象，并传入要生成二维码的内容和配置参数
      this.qrcode = new QRCode(qrcodeContainer, {
        text: _this.qrCode, // 要生成二维码的内容
        width: 80, // 二维码宽度
        height: 80, // 二维码高度
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>