<template>
  <div class="transfer-form">
    <p>绑定转账地址</p>
    <el-input
      type="text"
      v-model="controlConfig.address"
      clearable
      placeholder="请填写转账地址"
    ></el-input>
    <p style="margin-top: 30px">
      {{ controlType | controlTypeFilter1(controlType) }}
      <!-- {{ controlType == "rent" ? "小时笔数配置" : "包月笔数配置" }} -->
    </p>

    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="5" :xs="24" :sm="6">
        <p>
          {{ controlType | controlTypeFilter2(controlType)}}
          <!-- {{ controlType == "rent" ? "笔数数量" : "每天笔数数量" }} -->
        </p>
        <el-input
          type="text"
          v-model="controlConfig.energyNum"
        ></el-input>
      </el-col>
      <el-col :span="5" :xs="24" :sm="6">
        <p>TRX价格</p>
        <el-input
          type="text"
          v-model="controlConfig.trxPrice"
          placeholder="TRX价格"
        ></el-input>
      </el-col>
      <el-col :span="5" :xs="24" :sm="6" v-if="controlType != 3">
        <p>USDT价格</p>
        <el-input
          type="text"
          v-model="controlConfig.usdtPrice"
          placeholder="USDT价格"
        ></el-input>
      </el-col>
      <el-col :span="5" :xs="24" :sm="6" v-if="controlType  == 'rent'">
        <p>最大翻倍</p>
        <el-input
          type="text" 
          @input="handleInput"
          v-model="controlConfig.rate"
          placeholder="最大翻倍"
        ></el-input>
      </el-col>
      <!-- <el-col :span="5" :xs="24" :sm="6" v-if="controlType  == '3'">
        <p>包月无限制充值金额</p>
        <el-input
          type="text" 
          @input="handleInput"
          v-model="controlConfig.unlimitPrice"
          placeholder="请输入整数"
        ></el-input>
      </el-col> -->
      <!-- <el-col :span="5">
        <p>最大翻倍</p>
        <el-input type="text" v-model="rate" placeholder="最大翻倍"  :disabled="index !== 0"></el-input>
      </el-col> -->
      <el-col :span="4">
        <!-- <p>翻倍</p> -->
        <!-- <div style="height:40px;display: flex;align-items: center;">
          <el-switch
            disabled
            v-model="row.superposition"
            :active-value=1
            :inactive-value=0>
          </el-switch>
          <el-button type="danger" style="margin-left:20px" icon="el-icon-delete" @click="delControlConfig(index)" :disabled="controlConfig.length === 1">删除</el-button>
        </div> -->
      </el-col>
    </el-row>

    <!--<el-button type="primary" style="margin-top:20px" icon="el-icon-plus" @click="addControlConfig()" >新增</el-button> -->

    <div v-if="controlType != '3'">
      <p style="margin: 20px 0">{{ controlType == '3'? '包月无限制开关' : '代理开关'}}</p>
      <el-switch
        v-model="st"
        active-text="开启"
        inactive-text="关闭"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
    </div>

    <div style="width: 100%; display: flex; margin-top: 30px;justify-content: center;">
      <el-button type="primary" style="width: 50%" round @click="save()"
        >保存</el-button
      >
      <!-- <el-button v-if="controlType!=3" type="danger" style="flex: 1" round @click="reset()"
        >处理失败订单</el-button
      > -->
      <!-- <div style="flex: 1" v-else></div> -->
    </div>
  </div>
</template>

  
<script>
import { hourSave, monthSave, hourDeal, monthDeal, oneSave, hourEdit } from "@/api/login";
import { encryptCode } from "@/api/common.js";
export default {
  name: "TransferForm",
  props: {
    pageSize: {
      type: String,
      default: "medium",
    },
    initForm: {
      type: Object,
      default: {},
    },
    controlType: {
      type: String,
      default: "rent",
    },
  },
  data() {
    return {
      st: 0,
      controlConfig: {
        address: "",
        energyNum: 1,
        trxPrice: "",
        usdtPrice: "",
        superposition: 1,
        rate: '',
        unlimitPrice: ""
      },
    };
  },
  filters: {
    controlTypeFilter1(val) {
      let value = "小时"
      switch(val) {
        case "rent": value = "小时";break;
        case "2": value = "每天";break;
        case "3": value = "不限时";break;
      }
      return value + "笔数配置"
    },
    controlTypeFilter2(val) {
      let value = ""
      switch(val) {
        case "rent": value = "能量数量";break;
        case "2": value = "每天笔数数量";break;
        case "3": value = "笔数";break;
      }
      return value
    }
  },
  methods: {
    handleInput(value) {
      const regex = /^\d*$/; // 只允许输入数字
      if (!regex.test(value)) {
        this.inputValue = value.replace(/[^\d]/g, ''); // 替换非数字字符为空
      }
    },
    delControlConfig(index) {
      this.controlConfig.splice(index, 1);
    },
    addControlConfig() {
      this.controlConfig.push({
        energyNum: "",
        trxPrice: "",
        usdtPrice: "",
        superposition: "",
        rate: "",
      });
    },
    save() {
      let url = hourSave;
      if(this.controlType == "rent") {
        if(this.controlConfig.id) url = hourEdit;
      }
      else if (this.controlType == "2") {
        url = monthSave;
      } else if(this.controlType == "3") {
        url = oneSave
      }
      var data = {
        ...this.controlConfig,
        st: this.st,
      };
      url(data).then((res) => {
        let { code, error } = res.data;
        if (code == 200) {
          this.$message({
            message: "配置成功！",
            type: "success",
            duration: 1500
          });
          if(this.controlConfig.id && this.controlType == "rent") {
            this.close();
            this.$emit("close");
          } 
        }
      });
    },
    close() {
      this.st = 0;
          this.controlConfig = {
            address: "",
            energyNum: 1,
            trxPrice: "",
            usdtPrice: "",
            superposition: 1,
            rate: '',
            unlimitPrice: ""
          }
    },
    reset() {
      let url = hourDeal;
      if (this.controlType != "rent") url = monthDeal;
      url(encryptCode()).then((res) => {
        let { code, error } = res.data;
        if (code == 200) {
          this.$message({
            message: "操作成功！",
            type: "success",
            duration: 1500
          });
        }
      });
      // this.address = ''
      // this.st = '1';
      // this.controlConfig = {
      //   address: "",
      //   energyNum: 1,
      //   trxPrice: "",
      //   usdtPrice: "",
      //   superposition: 1,
      //   rate: 30,
      // };

    },
  },
  watch: {
    initForm: {
      handler: function (newVal) {
        // console.log(newVal);
        // if(newVal.orderId) {
        //   // 编辑
        //   this.address = newVal.address
        //   this.st = newVal.st
        //   this.controlConfig = newVal.controlConfig
        // } else {
        //   this.address = ''
        //   this.st = 1
        //   //this.controlConfig = []
        // }
        // this.st = newVal.st
        this.controlConfig = JSON.parse(JSON.stringify(newVal));
        this.st = this.controlConfig.st;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .el-input__inner {
  text-align: center;
}
</style>