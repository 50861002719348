    import axios from 'axios'
    import store from '@/store'
    import { Message } from 'element-ui'; // 引入 Message 组件
    import Cookies from 'js-cookie';
    const service = axios.create({
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'X-AppCode': 'jgpt-im-service',
            // 'token': sessionStorage.getItem('isToken'),
            // 'Authorization': sessionStorage.getItem('isToken'),
        },
        // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
        // withCredentials: true, // send cookies when cross-domain requests
        timeout: 300000 // request timeout
    })
    // const witheList = ['/energy/config','/energy/sellOrder'];
    // // 请求拦截器
    service.interceptors.request.use(
        function (config) {
            config.headers.Authorization = Cookies.get('isToken');
            return config;
        },
        function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );

    // 响应拦截器
    service.interceptors.response.use(
        function (response) {
            let {
                code,
                error
            } = response.data;
            if (code == 401) {
                clearInterval(window.userInfoTime);
                Cookies.remove("isToken");
                Cookies.remove("uid");
                Cookies.remove("userInfo");
                store.commit('setUserInfo',{
                    "username": "",
                    "balance": "--"
                })
                Message({
                    message: error,
                    type: 'warning'
                });
            } else if (code == 500) {
                Message({
                    message: error,
                    type: 'warning'
                });
            }
            return response;
        },
        function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );



    export default service