<template>
  <div id="app" :class="['menu-mode-' + menuMode, {'show-left-menu': !leftMenuFold}]" :style="{ height: isSafari == 1 && pageSize == 'mini' ? 'calc( 100vh - 75px)' : '100vh'}">
    <div class="app-left" v-if="!leftMenuFold">
      <div style="background-color: #409eff;display: flex;flex-direction: column;align-items: center; color:white;padding:15px 20px" v-if="userInfo&&userInfo.username">
        <img style="border-radius: 50%;width: 62px;" src="@/assets/img/photo.jpg" alt="">
        <p style="margin: 8px 0 10px 0">{{ userInfo.username }}</p>
        <p style="font-weight: 600;;">{{ userInfo.balance  }} <span style="font-weight: 500;font-size: 10px;color:#2c3e50">TRX</span> </p>
        <div style="display: flex;justify-content: space-between;width: 100%;">
          <div>
            <i class="el-icon-warning"></i>
            <el-button type="text" style="color:white;text-decoration: underline;margin-left:5px" @click="handleCommand('changePwd')">更改密码</el-button>
          </div>
          <div>
            <i class="el-icon-s-promotion"></i>
            <el-button type="text" style="color:white;text-decoration: underline;margin-left:5px" @click="userLogout">退出</el-button>
          </div>
        </div>
        <el-button type="primary" icon="el-icon-user-solid" style=" background-color: transparent;width: 100%;border-color: white;text-decoration: none;padding: 7px;" @click="openNewPage('https://t.me/tronfeeio')">联系客服</el-button>
      </div>
      <div v-else style="padding: 15px 0;background-color: white;display: flex;flex-direction: column;align-items: center;">
        <el-button type="warning" round style="padding: 5px 50px" @click="openLoginDialog()">
          <i class="el-icon-s-platform"></i>
          <span>登录</span>
        </el-button>
        <el-button type="primary" round style="padding: 5px 35px;margin:20px 0 0;" @click="openNewPage('https://t.me/tronfeeio')">
          <i class="el-icon-s-custom"></i>
          <span>联系客服</span>
        </el-button>
      </div>
      <el-menu v-if="menuMode === 'vertical'" :default-active="activeIndex" style="width:200px;height:100%;border-bottom: none;" mode="vertical" active-text-color="#409eff" text-color="#2c3e50" @select="handleSelect">
        <el-menu-item index="home">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <!-- <el-menu-item index="order">
          <i class="el-icon-s-data"></i>
          <span slot="title">最新订单</span>
        </el-menu-item> -->
        <el-menu-item index="pay">
          <i class="el-icon-s-flag"></i>
          <span slot="title">资源租用</span>
        </el-menu-item>
        <!-- <el-menu-item index="intelligentHosing">
          <i class="el-icon-s-opportunity"></i>
          <span slot="title">笔数模式</span>
        </el-menu-item> -->
        <el-menu-item index="transferRent">
          <i class="el-icon-s-marketing"></i>
          <span slot="title">代理模式</span>
        </el-menu-item>
        <el-menu-item index="interface">
          <i class="el-icon-s-order"></i>
          <span slot="title">API文档</span>
        </el-menu-item>
        <el-submenu index="userMore" v-if="userInfo.username">
          <template slot="title">
            <i class="el-icon-more"></i>
            <span slot="title">用户更多</span>
          </template>
          <el-menu-item index="userHome">
            <i class="el-icon-menu"></i>
            <span slot="title">用户中心</span>
          </el-menu-item>
          <!-- <el-menu-item index="userSpeed">
            <i class="el-icon-s-flag"></i>
            <span slot="title">资源速充</span>
          </el-menu-item> -->
          <el-menu-item index="userOrder">
            <i class="el-icon-s-data"></i>
            <span slot="title">用户订单</span>
          </el-menu-item>
          <el-menu-item index="balanceRecord">
            <i class="el-icon-s-marketing"></i>
            <span slot="title">余额详情</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="appRight" :style="{ marginLeft: !leftMenuFold ? '200px' : '0'}">
      <div class="app-right">
      <!-- 顶部 -->
      <div id="app-header">
        <template v-if="menuMode === 'vertical'">
          <i class="el-icon-s-unfold" style="font-size: 24px;margin-right:20px" v-if="leftMenuFold" @click="unFoldMenu"></i>
          <i class="el-icon-s-fold" style="font-size: 24px;margin-right:20px" v-else @click="foldMenu"></i>
        </template>
        <div id="header-top">
          <!-- 左侧名字 -->
          <div style="display: flex; align-items: center;">
            <div class="el-image" style=" height: 35px;width:53px;">
              <img src="@/assets/logo.png" class="el-image__inner">
            </div>
            <h2 style="margin: 0px 0px 0px 10px;">peee.io</h2>
          </div>
          <!-- 中间菜单 -->
          <el-menu v-if="menuMode === 'horizontal'" :default-active="activeIndex" style="border-bottom: none;" mode="horizontal" active-text-color="#409eff" text-color="#2c3e50" @select="handleSelect">
            <el-menu-item index="home">
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <!-- <el-menu-item index="order">
              <i class="el-icon-s-data"></i>
              <span slot="title">最新订单</span>
            </el-menu-item> -->
            <template v-if="menuShowMore">
              <el-submenu index="menuMore">
                <template slot="title">
                  <i class="el-icon-more"></i>
                  <span slot="title">更多选项</span>
                </template>
                <el-menu-item index="pay">
                  <i class="el-icon-s-flag"></i>
                  <span slot="title">资源租用</span>
                </el-menu-item>
                <!-- <el-menu-item index="intelligentHosing">
                  <i class="el-icon-s-opportunity"></i>
                  <span slot="title">笔数模式</span>
                </el-menu-item> -->
                <el-menu-item index="transferRent">
                  <i class="el-icon-s-marketing"></i>
                  <span slot="title">代理模式</span>
                </el-menu-item>
                <el-menu-item index="interface">
                  <i class="el-icon-s-order"></i>
                  <span slot="title">API文档</span>
                </el-menu-item>
                <template v-if="userInfo.username">
                  <el-submenu index="userMore">
                    <template slot="title">
                      <i class="el-icon-more"></i>
                      <span slot="title">用户更多</span>
                    </template>
                    <el-menu-item index="userHome">
                      <i class="el-icon-menu"></i>
                      <span slot="title">用户中心</span>
                    </el-menu-item>
                    <!-- <el-menu-item index="userSpeed">
                      <i class="el-icon-s-flag"></i>
                      <span slot="title">资源速充</span>
                    </el-menu-item> -->
                    <el-menu-item index="userOrder">
                      <i class="el-icon-s-data"></i>
                      <span slot="title">用户订单</span>
                    </el-menu-item>
                    <el-menu-item index="balanceRecord">
                      <i class="el-icon-s-marketing"></i>
                      <span slot="title">余额详情</span>
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item index="pay">
                <i class="el-icon-s-flag"></i>
                <span slot="title">资源租用</span>
              </el-menu-item>
              <!-- <el-menu-item index="intelligentHosing">
                <i class="el-icon-s-opportunity"></i>
                <span slot="title">笔数模式</span>
              </el-menu-item> -->
              <el-menu-item index="transferRent">
                <i class="el-icon-s-marketing"></i>
                <span slot="title">代理模式</span>
              </el-menu-item>
              <el-menu-item index="interface">
                <i class="el-icon-s-order"></i>
                <span slot="title">API文档</span>
              </el-menu-item>
              
              <template v-if="userInfo.username">
                <el-submenu index="userMore">
                  <template slot="title">
                    <i class="el-icon-more"></i>
                    <span slot="title">用户更多</span>
                  </template>
                  <el-menu-item index="userHome">
                    <i class="el-icon-menu"></i>
                    <span slot="title">用户中心</span>
                  </el-menu-item>
                  <!--<el-menu-item index="userSpeed">
                    <i class="el-icon-s-flag"></i>
                    <span slot="title">资源速充</span>
                  </el-menu-item> -->
                  <el-menu-item index="userOrder">
                    <i class="el-icon-s-data"></i>
                    <span slot="title">用户订单</span>
                  </el-menu-item>
                  <el-menu-item index="balanceRecord">
                    <i class="el-icon-s-marketing"></i>
                    <span slot="title">余额详情</span>
                  </el-menu-item>
                </el-submenu>
              </template>

            </template>
          </el-menu>
          <!-- 右侧按钮 -->
          <div style="padding-right:18px" v-if="menuMode === 'horizontal'">
            <div v-if="userInfo.username">
              <el-dropdown @command="handleCommand">
                <div style="display: flex;align-items: center;">
                  <img style="border-radius: 50%;width:40px;height:40px" src="@/assets/img/photo.jpg" alt="">
                  <div style="margin: 0 10px;text-align: left;">
                    <p>{{ userInfo.username }}</p>
                    <p style="font-weight: 600;font-size: 18px; color: #e6a23c;">{{ (userInfo.balance) }} <span style="font-weight: 500;font-size: 10px;color:#2c3e50">TRX</span> </p>
                  </div>
                  <i class="el-icon-arrow-down el-icon--right" style="font-size: 18px;"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="link"><i class="el-icon-s-custom"></i>联系客服</el-dropdown-item>
                  <el-dropdown-item command="changePwd"><i class="el-icon-warning"></i>更改密码</el-dropdown-item>
                  <el-dropdown-item command="logout" divided><i class="el-icon-s-promotion"></i>退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else style="display: flex; align-items: center;">
              <button type="button" class="el-button el-button--warning el-button--medium is-round base-shadow" @click="openLoginDialog()">
                <i class="el-icon-s-platform"></i>
                <span>登录</span>
              </button>
              <button type="button" class="el-button el-button--primary el-button--medium is-round base-shadow" @click="openNewPage('https://t.me/tronfeeio')">
                <i class="el-icon-s-custom"></i>
                <span>联系客服</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="app-main">
        <Main v-if="$route" :pageSize="pageSize" :style="{marginBottom:  isSafari == 1 && pageSize == 'mini' ? '75px' : '0px' }"></Main>
      </div>
    </div>
    </div>
    <!-- 登录弹窗 -->
    <el-dialog
      :visible.sync="loginDialogVisible"
      @close="closeLogin()"
      :append-to-body=true
      :close-on-click-modal=false
      :width="menuMode === 'vertical' ? '80%' : '50%'"
      custom-class="login-dialog">
      <div :class="['login', {'phone-show': menuMode === 'vertical'}]">
        <i @click="loginDialogVisible = false" class="el-icon-close close"></i>
        <div style="display: flex;width: 100%;">
          <div class="login-bg" style="flex:1;overflow: hidden;border-radius: 10px 0 0 10px;">
            <img style="width: 100%;height:100%;" src="@/assets/img/login.png" alt="">
          </div>
          <div style="flex:1;position: relative;">
            <el-menu :default-active="loginType" class="menu" mode="horizontal" active-text-color="#409eff" @select="loginTypeChange">
              <el-menu-item index="login">登录</el-menu-item>
              <el-menu-item index="register">注册</el-menu-item>
            </el-menu>
            <!-- <van-cell-group>
              <van-field v-model="loginForm.userNam" label="文本" placeholder="请输入用户名" />
            </van-cell-group> -->
            <!-- 登录 -->
            <el-form v-if="loginType === 'login'" :model="loginForm" status-icon :rules="rules" ref="loginForm" style="margin: 50px 40px 20px;">
              <el-form-item prop="userName">
                <el-input placeholder="请输入用户名" prefix-icon="el-icon-user" v-model="loginForm.userName"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入用户密码" prefix-icon="el-icon-lock" show-password v-model="loginForm.password" autocomplete="off"></el-input>
              </el-form-item>
              <!-- <el-form-item prop="code">
                <div style="display: flex;">
                  <el-input style="flex:1" placeholder="请输入验证码" prefix-icon="el-icon-warning-outline" v-model="loginForm.code"></el-input>
                  <img style="width: 86px;margin-left: 10px;" :src="codeSrc" alt="">
                </div>
              </el-form-item> -->
            </el-form>
            
            <!-- 注册 -->
            <el-form  v-if="loginType === 'register'" :model="registerForm" status-icon :rules="rules" ref="registerForm" style="margin: 20px 40px 20px;">
              <el-form-item prop="userName">
                <p style="font-weight: 700;font-size: 14px;margin-bottom: 10px;line-height: normal;">用户名只允许字母和数字，下划线_及破折号-组成的6~16位</p>
                <el-input placeholder="请输入用户名" prefix-icon="el-icon-user" v-model="registerForm.userName"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <p style="font-weight: 700;font-size: 14px;margin-bottom: 10px;line-height: normal;">密码只允许字母和数字，下划线_及破折号-组成的6~16位</p>
                <el-input placeholder="请输入用户密码" prefix-icon="el-icon-lock" show-password v-model="registerForm.password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="rePassword">
                <el-input placeholder="请再次输入用户密码" prefix-icon="el-icon-lock" show-password v-model="registerForm.rePassword" autocomplete="off"></el-input>
              </el-form-item>
              <!-- <el-form-item prop="code">
                <div style="display: flex;">
                  <el-input style="flex:1" placeholder="请输入验证码" prefix-icon="el-icon-warning-outline" v-model="loginForm.code"></el-input>
                  <img style="width: 86px;margin-left: 10px;" :src="codeSrc" alt="" @click="refreshCodeImg">
                </div>
              </el-form-item> -->
            </el-form>
            
            <div class="login-bottom">
              <el-button type="primary" v-if="loginType === 'login'" class="button-gradient login-btn" @click="userLogin">登录</el-button>
              <el-button type="primary" v-if="loginType === 'register'" class="button-gradient login-btn" @click="userRegister">注册</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>  

    <!-- 重置密码 -->
    <el-dialog
      :visible.sync="changePwdDialogVisible"
      :append-to-body=true
      :close-on-click-modal=false
      @close="changePwdClose"
      :title="'重置密码'"
      :width="menuMode === 'vertical' ? '80%' : '50%'"
      custom-class="change-pwd-dialog">
      <div>
        <el-form :model="changePwdForm" status-icon :rules="changePwdFormRules" ref="changePwdForm" style="margin: 20px 40px 20px;">
          <el-form-item prop="password">
            <el-input placeholder="请输入用户密码" prefix-icon="el-icon-lock" show-password v-model="changePwdForm.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input placeholder="请输入用户新密码" prefix-icon="el-icon-lock" show-password v-model="changePwdForm.newPassword" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="rePassword">
            <el-input placeholder="请再次输入用户新密码" prefix-icon="el-icon-lock" show-password v-model="changePwdForm.rePassword" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="button-gradient login-btn" @click="changePwd">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import router from './router';
import Main from '@/views/main.vue';
import { register,login,userInfo,changeLoginPwd } from '@/api/login';
import request from '@/api/index'
import axios from 'axios'
import eventBus from './utils/eventBus';
import Cookies from 'js-cookie';
export default {
  name: 'App',
  components: { Main },
  data(){
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入用户密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入用户密码'));
      } else if (value !== this.changePwdForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    var validateNewPwd = (rule, value, callback) => {
      if (value.length < 5 || value.length > 20) {
        callback(new Error('长度在 5 到 20 个字符'));
      }else {
        callback();
      }
    };

    var validateUserName = (rule, value, callback) => {
      let pattern = /^[a-zA-Z0-9_-]{6,16}$/; //用户名6-16位（字母，数字，下划线，减号）
      if(value === '') {
        callback(new Error('用户名不能为空'));
      } else if(!(pattern.test(value))) {
        callback(new Error('长度在 6 到 16 个字符（字母，数字，下划线，减号）'));
      } else {
        callback();
      }
    }

    var validatePassWord = (rule, value, callback) => {
      let pattern = /^[a-zA-Z0-9_-]{6,16}$/; //用户名4-16位（字母，数字，下划线，减号）
      if(value === '') {
        callback(new Error('密码不能为空'));
      } else if(!(pattern.test(value))) {
        callback(new Error('长度在 6 到 16 个字符（字母，数字，下划线，减号）'));
      } else {
        callback();
      }
    }

    return {
      isSafari: localStorage.getItem('isSafari'),
      pageSize: 'medium', // 页面尺寸  /small /mini
      menuMode: 'horizontal',//vertical
      leftMenuFold: false, // 左侧菜单收起
      menuShowMore: false,
      activeIndex: "home",
      loginType: 'login',
      loginDialogVisible: false,
      loginForm: {
        userName: '',
        password: '',
        code: ''
      },
      registerForm: {
        userName: '',
        password: '',
        rePassword: '',
        code: ''
      },
      rules: {
        userName: [
          { validator: validateUserName, trigger: 'blur' }
        ],
        password: [
          { validator: validatePassWord,  trigger: 'blur'  }
        ],
        rePassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur'  }
        ]
      },
      // 验证码图片路径
      codeSrc: '',
      // 修改密码
      changePwdDialogVisible: false,
      // 重置密码表单
      changePwdForm: {
        password: '',
        newPassword: '',
        rePassword: '',
      },
      changePwdFormRules: {
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur'  }
        ],
        newPassword: [
          { validator: validatePassWord, trigger: 'blur' }
        ],
        rePassword: [
          { validator: validatePass3, trigger: 'blur' }
        ]
      },
      userInfoTime: null
    }
  },
  methods: {
    handleSelect: (index, indexPath)=>{
      router.push({path: index})
    },
    loginTypeChange (index){
      this.loginType = index;
    },
    openNewPage (url) {
      window.open("https://t.me/" + this.globalConfig.tgService)
    },
    openLoginDialog(){
      this.loginDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.loginForm?.clearValidate();
        this.$refs.registerForm?.clearValidate();
      })
    },
    closeLogin() {
      this.loginType = 'login';
         this.loginForm = {
        userName: '',
        password: '',
        code: ''
      };
      this.registerForm = {
        userName: '',
        password: '',
        rePassword: '',
        code: ''
      }
    },
    // 点击刷新验证码图片
    refreshCodeImg() {

    },
    // 收起左侧菜单
    foldMenu() {
     
      this.leftMenuFold = true
    },
    // 展开左侧菜单
    unFoldMenu() {
      this.leftMenuFold = false

    },
    resetPage(pageWidth) {
      if(pageWidth < 766) { // 可视宽度小于950 导航栏到左侧
        this.menuMode = 'vertical'
        this.leftMenuFold = true
        this.pageSize = 'mini'
      } else{
        this.menuMode = 'horizontal'
        this.leftMenuFold = true
        if(pageWidth < 1566) {
          this.menuShowMore = pageWidth < 1100 ? true : false;
          this.pageSize = 'small'
        } else {
          this.menuShowMore = false
          this.pageSize = 'medium'
        }
        localStorage.removeItem('isSafari')
      }
    },
    // 用户登录
    userLogin() {
      debugger
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          var data = {
            account: this.loginForm.userName,
            pwd: this.loginForm.password
          }
          login(data).then(res =>{
            let { code,data,error } = res.data;
            if(code == 200) {
              this.$message({
                message: '登录成功！',
                type: 'success',
                duration: 1500
              });
              Cookies.set('isToken', data.token);
              debugger
              Cookies.set('uid', data.uid);
              this.$store.dispatch('login',data);
              //this.$store.dispatch('getConfig');
              // request = axios.create({
              //   headers: {
              //     'Content-Type': 'application/json;charset=UTF-8',
              //     'X-Requested-With': 'XMLHttpRequest',
              //     'X-AppCode': 'jgpt-im-service',
              //     'token': sessionStorage.getItem('isToken'),
              //     'Authorization': sessionStorage.getItem('isToken')
              //   }
              // })
              this.$store.dispatch('getUserInfo').then(res =>{
                this.$router.push('/userHome')
              })
              if(window.userInfoTime) clearInterval(window.userInfoTime);
              window.userInfoTime = setInterval(()=>{
                      this.getUserInfo();
              },5000)
              // 关闭弹窗
              this.loginDialogVisible = false;
            }
          })
        } else {
          return false;
        }
      })
    },
    getUserInfo() {
      this.$store.dispatch('getUserInfo')
    },
    // 用户注册
    userRegister() {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          var data = {
            account: this.registerForm.userName,
            pwd: this.registerForm.password
          }
          register(data).then(res =>{
            let { code,error } = res.data;
            if(code == 200) {
              this.$message({
                message: '注册成功！',
                type: 'success',
                duration: 1500
              });
              // 关闭弹窗
              this.loginDialogVisible = false
            }
          })
          // 调用api
    
        } else {
          return false;
        }
      })
    },
    // 用户登出
    userLogout() {
      this.$store.dispatch({
        type: 'logout'
      })
      clearInterval(this.userInfoTime);
    },
    // 登录后右上角下拉指令
    handleCommand(command) {
      if(command === 'link') {
        this.openNewPage('https://t.me/tronfeeio')
      } else if(command === 'changePwd') {
        this.changePwdDialogVisible = true
      } else if(command === 'logout') {
        this.userLogout()
      }
    },
    // 重置密码
    changePwd() {
      this.$refs['changePwdForm'].validate((valid) => {
        if (valid) {
          var data = {
            pwd: this.changePwdForm.newPassword,
            oldPwd: this.changePwdForm.password
          }
          changeLoginPwd(data).then(res =>{
             let { code,error } = res.data;
             if(code == 200) {
              this.$message({
                message: '修改成功！',
                type: 'success',
                duration: 1500
              });
              // 关闭弹窗
              this.changePwdDialogVisible = false
            }
          })
          // 提交到api
        } else {
          return false;
        }
      });
    },
    changePwdClose() {
      this.$refs.changePwdForm.resetFields();
    }
  },
  watch: {
    '$route': {
      handler: function(newVal, oldVal) {
        if(newVal) {
          this.activeIndex = newVal.name
        } else {
          this.activeIndex = oldVal.name
        }
                // 当点击返回顶部按钮时触发
        function backToTop() {
          if (window.scrollTo) {
            window.scrollTo({
              top: 0,
              behavior: "smooth" // 平滑滚动到顶部
            });
          } else {
            window.scrollTo(0, 0); // 兼容性处理
          }
        }
        backToTop();
        // 滚动到页面顶部
      let dom = document.getElementsByClassName('app-main')[0]
      if(dom) {
        dom.scrollTop = 0;
        document.documentElement.scrollTop = 0; // Chrome、Firefox、IE、Opera、Safari
        document.body.scrollTop = 0; // 兼容旧版 IE
      }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
    globalConfig() {
      return this.$store.getters.globalConfig
    },
  },
  created() {
    const width = document.body.clientWidth
    this.resetPage(width)
  },
  mounted() {
    eventBus.$on('notToken',(e)=>{
        if(e == 'buy') this.loginDialogVisible = true;
    })
    const width = document.body.clientWidth
    this.resetPage(width)

    const that = this
    window.onresize = () =>{
      const width = document.body.clientWidth
      that.resetPage(width)
    }

    this.$store.dispatch({
        type: 'getConfig'
      })
    setInterval(() =>{
      this.$store.dispatch({
        type: 'getConfig'
      })
    }, 10000)
    if(Cookies.get('isToken')) {
      this.getUserInfo();
      if(window.userInfoTime) clearInterval(window.userInfoTime);
      window.userInfoTime = setInterval(()=>{
              this.getUserInfo();
      },5000)
      
    } else {
      this.$router.push('/home');
    }
      window.onload = function () {
        document.addEventListener('gesturestart', function (e) {
          e.preventDefault();
        });
        document.addEventListener('dblclick', function (e) {
          e.preventDefault();
        });
        document.addEventListener('touchstart', function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        });
        var lastTouchEnd = 0;
        document.addEventListener('touchend', function (event) {
          var now = (new Date()).getTime();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        }, false);
      };
  },
  beforeDestroy() {
    eventBus.$off('notToken');
  },
}
</script>
<style>
.scroll-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* 解决 Safari 滚动条问题 */
}
/* 针对iOS Safari禁用页面缩放 */
@media screen and (max-device-width: 480px) {
  html {
    -webkit-text-size-adjust: 100%;
  }
}


</style>
<style lang="scss">
*{
  padding:0;
  margin: 0;
}
body{
  overscroll-behavior-y: contain; /* 兼容性较好的属性，可尝试使用 */
  overflow-x: hidden;
  background-color: #f0f8ff;
}
input, input:focus,
textarea, textarea:focus,
selsect, select:focus {
  font-size: 14px !important;
}
.ellipsis{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
#app {
  width:100%;
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  overflow: hidden;
  .appRight {
    flex: 1;
    // overflow: hidden;
  }
  .app-right{
    width: 100%;
    // overflow: hidden;
  }
  #app-header{
    width: calc(100% - 40px);
    background-color: #fff;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 1px solid #e5e7eb;
    padding: 0 20px;
    height: 60px;
    #header-top{
      flex:1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .el-menu-item, .el-submenu .el-submenu__title{
      margin: 0 20px!important;
      padding: 0!important;
      font-weight: 500!important;
      font-size: 18px!important;
      letter-spacing: 2px!important;
    }
  }
  .app-main{
    padding: 60px 0 0 0;
    position: relative;
    z-index: 0;
    transition: transform .7s,width .7s;
    overflow: auto;
    height: calc( 100vh - 60px );
  }
}

.el-menu-item{
  margin: 0 20px!important;
  padding: 0!important;
  font-weight: 500!important;
  font-size: 18px!important;
  letter-spacing: 2px!important;
}
.el-submenu .el-submenu__title{
  margin: 0 20px!important;
  padding: 0!important;
  font-weight: 500!important;
  font-size: 18px!important;
  letter-spacing: 2px!important;
}

.button-gradient{
  background-image: linear-gradient(0deg,#4481eb 0,#04befe);
  border-radius: 20px;
  padding: 12px 23px;
  box-shadow: 0 2px 4px rgba(0,0,0,.12), 0 0 6px rgba(0,0,0,.04);
  width: 100%
}

.login-dialog{
  border-radius: 10px!important;
  box-shadow: -1px 1px 5px #333;
  border: none;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
    .login{
      height: 520px;
      display: flex;
      width: 100%;
      position: relative;
      overflow: hidden;
    }
    .close{
      line-height: 40px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 40px;
      height: 40px;
      background: #409eff;
      text-align: center;
      box-shadow: 0 1px 1px #ccc;
      border-radius: 0 0 0 40px;
      cursor: pointer;
      z-index: 1;
    }
  }
  .menu{
    width: 100%;
    display: flex;
    border-radius: 10px;
    .el-menu-item{
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
      border-bottom: 3px solid #409eff!important;
    }
  }
  .login-bottom{
    bottom: 40px; position: absolute;width: 100%;padding: 0px 40px; width: calc(100% - 80px);
  }
  .phone-show{
    width: 70%;
    .login-bg{
      display: none;
    }
    .login-btn{
      width: 100%;
    }
    .login-bottom{
      width: calc(100% - 80px);
      left: 0
    }
  }
}

#app.menu-mode-vertical{
  // height: calc( 100vh - 75px );
  .app-left{
    width: 200px;
    text-align: left;
    transition: transform .7s,width .7s;
    box-shadow: 2px 0 10px rgba(0,0,0,.2);
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
  }
  .app-right{
    height: 100%;
    width: 100vw;
    overflow: auto hidden;
  }
  .app-main {
    height: calc( 100% - 60px ) !important;
    overflow: auto;
    margin-top: 60px;
    padding: 0;
  }
  .appRight {
    flex: 1;
    overflow: auto hidden;
  }
  &.show-left-menu #app-header{
    // width: calc(100% - 240px);
    flex: 1;
    left: 200px
  }
  .el-submenu .el-menu-item{
    min-width: auto;
  }
}
</style>

