<template>
  <!-- 订单续费 -->
  <el-dialog
    title="订单续费"
    :visible.sync="orderRenewDialogVisible"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :append-to-body="true">
    <div style="width: 500px; text-align: left; margin: auto;">
      <p>续费天数</p>
      <el-input-number v-model="orderRenewForm.days" :min="3" style="width:440px"></el-input-number> 天
      <div style="padding: 15px 20px; width: 400px;margin-top:30px;background-color: #ecf5ff;border-radius: 5px;border-color: #d9ecff;">
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">订单金额</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 600;">{{ (orderRenewForm.days * 2.24).toFixed(2) }}TRX</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">订单数量</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">{{ orderRenewForm.payNums }}</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">资源类型</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">能量</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">一天单价</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">70SUN</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">追加时长</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">{{ orderRenewForm.days }}天</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">起始时间</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">{{ orderRenewForm.startTime }}</span>
        </div>
        <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
          <span class="left" style="width: 120px;">结束时间</span>
          <span class="middle">:</span>
          <span class="right phone-right primary-color" style="font-weight: 500;">{{ orderRenewForm.endTime }}</span>
        </div>
      </div>

      <div style="width: 100%;display: flex;margin-top:30px">
        <el-button round type="warning" style="width:215px;padding: 7px">地址支付</el-button>
        <el-button round type="success" style="width:215px;padding: 7px">账号支付</el-button>
      </div>
    </div>
  </el-dialog>
</template>
    
  <script>
    export default {
      name: 'UserSpeedView',
      props: {
        pageSize: {
          type: String, 
          default: 'medium'
        },
        dialogVisible: {
          type: Boolean,
          default: false
        },
        agentDetail: {
          type: Object,
          default: ()=> {
            return {
              payNums: 0
            }
          }
        }
      },
      data(){
        return {
          orderRenewDialogVisible: false,
          orderRenewForm: {
            days: 3,
            payNums: 0,
            startTime: '',
            endTime: ''
          },
        }
      },
      methods: {
        close() {
          this.$emit('close')
        },
        getTableData(orderId) {
  
        },
        sbumit() {
          // console.log(this.form)
  
          this.close()
        }
      },
      watch: {
        agentDetail: {
          handler: function(newVal){
            this.orderRenewForm.payNums = newVal.payNums
            this.orderRenewForm.startTime = dayjs(row.recoveryTime).format('YYYY-MM-DD HH:mm:ss') 
            this.orderRenewForm.endTime = dayjs(row.recoveryTime).add(this.orderRenewForm.days*1, 'day').format('YYYY-MM-DD HH:mm:ss')
          },
          deep: true
        },
        dialogVisible: function(newVal) {
          this.orderRenewDialogVisible = newVal
        }
      }
    }
  </script>
    
  <style lang="scss">
  
  </style>