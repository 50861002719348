<template>
  <div class="user-speed">
    <div style="display: flex;justify-content: space-around;flex-wrap: wrap;">

      <div class="left-card">
        <el-card>
          <div slot="header" style="display: flex;justify-content: space-between;align-items: center;">
            <span style="font-size: 16px">速充资源</span>
          </div>

          <div style="margin-bottom: 22px">
            <div style="color:#606266;margin-bottom: 3px;">下单模式</div>
            <el-radio-group v-model="mode" class="order-type-group" style="margin: 0;">
              <el-radio-button label="manual" style="width: 50%">手动下单</el-radio-button>
              <el-radio-button label="auto" style="width: 50%">智能下单</el-radio-button>
            </el-radio-group>
          </div>

          <div v-if="mode === 'manual'">
            <div style="margin-bottom: 22px;">
              <div style="color:#606266;margin-bottom: 3px;">速充类型</div>
              <div  style="display: flex;">
                <el-radio v-model="speedType" label="1" border style="flex:1;text-align: center;">能量</el-radio>
                <el-radio v-model="speedType" label="2" border style="flex:1;text-align: center;">带宽</el-radio>
              </div>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="color:#606266;margin-bottom: 3px;">速充地址</div>
              <el-input placeholder="请填写速充地址" v-model="address" clearable></el-input>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="color:#606266;margin-bottom: 3px;">速充数量</div>
              <el-input-number placeholder="请填写速充数量" v-model="count" controls-position="right" :step="10000"></el-input-number>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">详细</div>
              <div style="line-height: 25px;">
                <label class="left">地址数量：</label>
                <span style="color:#e6a23c">0 个</span>
              </div>
              <div style="line-height: 25px;">
                <label class="left">预计消费：</label>
                <span style="color:#e6a23c">0 TRX</span>
              </div>
              <div style="line-height: 25px;">
                <label class="left">账号余额：</label>
                <span style="color:#e6a23c">50 TRX</span>
              </div>
            </div>
          </div>

          <div v-else>
            <div style="margin-bottom: 22px;">
              <div style="color:#606266;margin-bottom: 3px;">速充地址</div>
              <el-input placeholder="请填写速充地址" v-model="address" clearable></el-input>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="color:#606266;margin-bottom: 52px;">智能速充参数配置</div>

              <el-divider content-position="left">能量</el-divider>
              <el-switch
                  v-model="energyState"
                  active-text="自动速充开"
                  inactive-text="自动速充关"
                  active-color="#13ce66"
                  :active-value=1
                  :inactive-value=0
                  style="margin: 15px 0">
              </el-switch>

              <div style="margin-bottom: 15px;">
                <div style="line-height: 25px;">
                  请填写触发速充的条件，地址小于该值自动速充，不能小于<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">10</span>万
                </div>
                <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="energyAutoLimitNums" clearable></el-input>
              </div>

              <div style="margin-bottom: 15px;">
                <div style="line-height: 25px;">
                  请填写自动速充数量的有效范围。至少<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">100</span>万，
                  至多<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">100</span>万
                </div>
                <div style="display: flex; gap: 15px; flex-wrap: wrap;">
                  <el-input placeholder="1000000" disabled style="flex: 1 1 300px">
                    <template slot="prepend">至少：</template>
                  </el-input>
                  <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="energyMax" style="flex: 1 1 300px">
                    <template slot="prepend">至多：</template>
                  </el-input>
                </div>
              </div>

              <el-divider content-position="left" style="margin-top: 30px">带宽</el-divider>
              <el-switch
                  v-model="bandwidthState"
                  active-text="自动速充开"
                  inactive-text="自动速充关"
                  active-color="#13ce66"
                  :active-value=1
                  :inactive-value=0
                  style="margin: 15px 0">
              </el-switch>

              <div style="margin-bottom: 15px;">
                <div style="line-height: 25px;">
                  请填写触发速充的条件，地址小于该值自动速充，不能小于<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">0.5</span>万
                </div>
                <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于0.5万" v-model="bandwidthAutoLimitNums" clearable></el-input>
              </div>

              <div style="margin-bottom: 15px;">
                <div style="line-height: 25px;">
                  请填写自动速充数量的有效范围。至少<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">2</span>万，
                  至多<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">2</span>万
                </div>
                <div style="display: flex; gap: 15px; flex-wrap: wrap;">
                  <el-input placeholder="1000000" disabled style="flex: 1 1 300px">
                    <template slot="prepend">至少：</template>
                  </el-input>
                  <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="bandwidthMax" style="flex: 1 1 300px">
                    <template slot="prepend">至多：</template>
                  </el-input>
                </div>
              </div>
            </div>

            <el-alert title="保持余额充足，否则智能下单将停止" type="warning" show-icon style="margin-bottom: 22px;padding: 18px;"></el-alert>
          </div>

          <el-button type="primary" round @click="accountPay" style="width: 100%">提交</el-button>

        </el-card>
      </div>

      <div class="right-card">
        <el-tabs type="border-card">
          <el-tab-pane label="速充订单">
            <!-- 表格 -->
            <el-table
              :data="manualTableData"
              style="width: 100%">
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="智能订单">
            <div v-if="pageSize === 'mini'">
              <div style="position: relative;margin-top: 50px" v-for="(item, index) in autoTableData">
                <div class="card-right-index" style="z-index:2">
                  <div style="padding: 0px 10px;">{{ index + 1 }}</div>
                </div>
                <el-card class="order-page-list-item" >
                  <div class="list-row-item">
                    <div class="row-item-left">接收地址</div>
                    <div class="row-item-right">
                      <i @click="copy(item.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                      <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 140px;margin-left: 5px;" class="ellipsis">{{ item.receiveAddress }}</span>
                    </div>
                  </div>
                  <div class="list-row-item" style="height: auto">
                    <div class="row-item-left">能量消费详情</div>
                    <div class="row-item-right">
                      <div>
                        <div>能量总消费: <span style="color: #e6a23c;">{{ item.totalEnergyMoney }}</span></div>
                        <div>总速充数量: {{ item.totalEnergyNums }}</div>
                        <div>总速充次数: {{ item.totalEnergyTimes }} </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-row-item" style="height: auto">
                    <div class="row-item-left">带宽消费详情</div>
                    <div class="row-item-right">
                      <div>
                        <div>带宽总消费: <span style="color: #e6a23c;">{{ item.totalBandwidthMoney }}</span></div>
                        <div>总速充数量: {{ item.totalBandwidthNums }}</div>
                        <div>总速充次数: {{ item.totalBandwidthTimes }} </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-row-item" style="height: auto">
                    <div class="row-item-left">能量速充配置</div>
                    <div class="row-item-right">
                      <div>
                        能量速充开关:
                        <el-switch
                          disabled
                          v-model="item.energyState"
                          active-color="#13ce66"
                          :active-value=1
                          :inactive-value=0>
                        </el-switch>
                        <div>速充触发条件: {{ item.energyAutoLimitNums / 10000 }}万</div>
                        <div>速充最小数量: {{ item.energyMin / 10000 }}万</div>
                        <div>速充最大数量: {{ item.energyMax  / 10000}}万 </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-row-item" style="height: auto">
                    <div class="row-item-left">带宽速充配置</div>
                    <div class="row-item-right">
                      <div>
                        带宽速充开关:
                        <el-switch
                          disabled
                          v-model="item.bandwidthState"
                          active-color="#13ce66"
                          :active-value=1
                          :inactive-value=0>
                        </el-switch>
                        <div>速充触发条件: {{ item.bandwidthAutoLimitNums / 10000 }}万</div>
                        <div>速充最小数量: {{ item.bandwidthMin / 10000 }}万</div>
                        <div>速充最大数量: {{ item.bandwidthMax  / 10000}}万 </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-row-item">
                    <div class="row-item-left">创建时间</div>
                    <div class="row-item-right">
                      {{ item.createTime }}
                    </div>
                  </div>
                  <div style="width: 100%;margin-top:20px">
                    <el-button plain round @click="copy(item.orderId)" style="border-color: #409eff;color: #409eff;width:100%;padding:5px">复制订单ID</el-button>
                    <el-button plain round @click="editAutoOrder(item)" style="border-color: #409eff;color: #409eff;width:100%;padding:5px;margin: 10px 0 0 0 ">编辑</el-button>
                  </div>
                </el-card>
              </div>
            </div>
            <!-- 表格 -->
            <el-table
              v-else
              :data="autoTableData"
              style="width: 100%">
              <el-table-column
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                label="接收地址"
                width="180">
                <template slot-scope="scope">
                  <i @click="copy(scope.row.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                  <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 140px;margin-left: 5px;" class="ellipsis">{{ scope.row.receiveAddress }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="能量消费详情">
                <template slot-scope="scope">
                  <div style="margin-top:30px">
                    <div>能量总消费: {{ scope.row.totalEnergyMoney }}</div>
                    <div>总速充数量: {{ scope.row.totalEnergyNums }}</div>
                    <div>总速充次数: {{ scope.row.totalEnergyTimes }} </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="带宽消费详情">
                <template slot-scope="scope">
                  <div style="margin-top:30px">
                    <div>带宽总消费: {{ scope.row.totalBandwidthMoney }}</div>
                    <div>总速充数量: {{ scope.row.totalBandwidthNums }}</div>
                    <div>总速充次数: {{ scope.row.totalBandwidthTimes }} </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="能量速充配置">
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      disabled
                      v-model="scope.row.energyState"
                      active-color="#13ce66"
                      :active-value=1
                      :inactive-value=0>
                    </el-switch>
                    <div>速充触发条件: {{ scope.row.energyAutoLimitNums / 10000 }}万</div>
                    <div>速充最小数量: {{ scope.row.energyMin / 10000 }}万</div>
                    <div>速充最大数量: {{ scope.row.energyMax  / 10000}}万 </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="带宽速充配置">
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      disabled
                      v-model="scope.row.bandwidthState"
                      active-color="#13ce66"
                      :active-value=1
                      :inactive-value=0>
                    </el-switch>
                    <div>速充触发条件: {{ scope.row.bandwidthAutoLimitNums / 10000 }}万</div>
                    <div>速充最小数量: {{ scope.row.bandwidthMin / 10000 }}万</div>
                    <div>速充最大数量: {{ scope.row.bandwidthMax  / 10000}}万 </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                width="100">
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button plain round @click="copy(scope.row.orderId)" style="border-color: #409eff;color: #409eff;width:100%;padding:5px;">复制订单ID</el-button>
                  <el-button plain round @click="editAutoOrder(scope.row)" style="margin: 10px 0 0 0;border-color: #409eff;color: #409eff;width:100%;padding:5px 20px">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>


    <AutoOrderDialog
      :dialogVisible.sync="AutoOrderDialogVisible"
      :orderForm="AutoOrderDialogForm"
      :pageSize="pageSize">
    </AutoOrderDialog>
  </div>
</template>

<script>
import AutoOrderDialog from './userSpeed/auto-order-dialog.vue'
export default {
  name: 'UserSpeedView',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    }
  },
  components: { AutoOrderDialog },
  data(){
    return {
      // 模式
      mode: 'manual',
      // 速充类型
      speedType: '1',
      // 速充地址
      address: '',
      // 速充数量
      count: '',
      // 能量速充开关
      energyState: '',
      // 能量数量
      energyAutoLimitNums: 1000000,
      energyMax: 1000000,
      // 带宽速充开关
      bandwidthState: '',
      // 带宽数量
      bandwidthAutoLimitNums: 5000,
      bandwidthMax: 20000,
      // 速充订单
      manualTableData:[],
      // 表格数据
      autoTableData: [
        {
          "orderId": 1709822785140160,
          "energyState": 0,
          "bandwidthState": 0,
          "receiveAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm",
          "energyAutoLimitNums": 100000,
          "bandwidthAutoLimitNums": 5000,
          "energyMin": 1000000,
          "energyMax": 1000000,
          "bandwidthMin": 20000,
          "bandwidthMax": 20000,
          "totalEnergyNums": 0,
          "totalBandwidthNums": 0,
          "totalEnergyTimes": 0,
          "totalBandwidthTimes": 0,
          "totalEnergyMoney": "0TRX",
          "totalBandwidthMoney": "0TRX",
          "createTime": "2024-03-07 22:46:25"
        }
      ],
      // 智能订单弹窗
      AutoOrderDialogVisible: false,
      AutoOrderDialogForm: {}
    }
  },
  methods: {
    // 编辑智能订单
    editAutoOrder(row) {
      this.AutoOrderDialogVisible = true
      this.AutoOrderDialogForm = row
    },
    accountPay() {

    },
    // 复制接收地址
    copy(mesg){
      const input = document.createElement("input"); // 创建输入框元素   
      input.value = mesg; // 设置输入框的值为要复制的内容
      document.body.appendChild(input); // 将输入框添加到页面中
      input.select(); // 选中输入框中的内容
      if (document.execCommand('copy')) { // 如果复制成功
        this.$message({
          message: '复制成功',
          type: 'success'
        });
      } else { this.$message({
          message: '复制失败',
          type: 'error'
        });
      }
      document.body.removeChild(input); // 移除输入框
    },
  }
}
</script>

<style scoped lang="scss">
.user-speed {
  text-align: left;
  font-size: 14px;;
  .left-card{
    flex: 1;
    flex-basis: 400px;
    margin: 15px;

    .el-input-number {
      width: 100%;
    }

    .el-divider {
      margin-top: 45px;

      .el-divider__text {
        font-weight: 600;
      }
    }

  }

  .right-card{
    flex: 2;
    flex-basis: 800px;
    margin: 15px;
    .el-tabs{
      height: calc(100%);
    }
  }
}
</style>