<template>
  <router-view :pageSize="pageSize"></router-view>
</template>

<script>

export default {
  name: 'Main',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    }
  },
  data: function(){
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">

</style>


