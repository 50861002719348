<template>
  <el-dialog
    :title="'统计【'+ dialogForm.controlAddress +'】'"
    :visible.sync="statisticsDialogVisible"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :before-close="close"
    custom-class="statistics-dialog"
    :append-to-body="true">
    <div style="display: flex;flex-wrap: wrap;gap: 20px;background: white;padding: 20px;">
      <el-date-picker
        style="flex:1 1 450px"
        v-model="timeFormat"
        clearable
        type="date"
        placeholder="选择日期">
      </el-date-picker>
      <div style="flex:1 1 450px;display:flex;">
        <el-button style="flex:1" type="primary" round @click="getTableData"> 搜索 </el-button>
        <el-button style="flex:1" type="warning" round @click="reset"> 重置 </el-button>
      </div>
    </div>

    <div v-if="pageSize === 'mini'">
      <div class="order-page-list-item" style="margin-top:20px;padding: 30px 20px;" v-for="(item, index) in tableData">
        <div class="card-right-index">
          <div style="padding: 0px 10px;">{{ index + 1 }}</div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">日期</div>
          <div class="row-item-right">
            <el-tag size="mini" type='primary' style="font-weight: 600;">{{ item.date }}</el-tag>
          </div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">触发次数</div>
          <div class="row-item-right">
            {{ item.count }}
          </div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">能量数</div>
          <div class="row-item-right">
            {{ item.payNums }}
          </div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">收款TRX</div>
          <div class="row-item-right">
            <span style="color:#e6a23c">{{ item.collectTrx }} TRX</span>
          </div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">收款USDT</div>
          <div class="row-item-right">
            <span style="color:#e6a23c">{{ item.collectUsdt }} USDT</span>
          </div>
        </div>
        <div class="list-row-item">
          <div class="row-item-left">账户消费</div>
          <div class="row-item-right">
            <span style="color:#e6a23c">{{ item.payMoney }} USDT</span>
          </div>
        </div>
        
        <div style="display: flex;margin-top: 20px">
          <el-button plain round disabled style="padding: 5px;flex:1">详细</el-button>
        </div>
      </div>
    </div>

    <el-table
      v-else
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top:30px;padding: 0 20px">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="date"
        label="日期">
        <template slot-scope="scope">
          <el-tag type='primary' style="font-weight: 600;">{{ scope.row.date }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="count"
        label="触发次数">
      </el-table-column>
      <el-table-column
        prop="payNums"
        label="能量数">
      </el-table-column>
      <el-table-column
        prop="collectTrx"
        label="收款TRX">
        <template slot-scope="scope">
          <span style="color:#e6a23c">{{ scope.row.collectTrx }} TRX</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="collectUsdt"
        label="收款USDT">
        <template slot-scope="scope">
          <span style="color:#e6a23c">{{ scope.row.collectUsdt }} USDT</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="payMoney"
        label="账户消费">
        <template slot-scope="scope">
          <span style="color:#e6a23c">{{ scope.row.payMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="详细">
        <template slot-scope="scope">
          <el-button disabled round plain style="padding: 5px 40px">详细</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
  
<script>
export default {
  name: 'StatisticsDialog',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogForm: {
      type: Object,
      default: ()=>{return {
        orderId: '',
        controlAddress: ''
      }}
    }
  },
  data() {
    return {
      statisticsDialogVisible: false,
      transferForm: {},
      timeFormat: '',
      loading:false,
      tableData: []
    }
  },
  watch: {
    dialogVisible: {
      handler: function(newVal) {
        this.statisticsDialogVisible = newVal
        this.transferForm = JSON.parse(JSON.stringify(this.dialogForm))
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getTableData () {
      this.loading = true
      const searchInfo = {
        "page": 1,
        "controlAddress": this.transferForm.controlAddress,
        "search": {
          "timeFormat": this.timeFormat
        }
      }
      // console.log(searchInfo)
      this.tableData = [{
        "date": "2024-03-12",
        "payNums": 0,
        "collectTrx": 0,
        "collectUsdt": 0,
        "payMoney": 0,
        "count": 0,
        "controlAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm",
        "controlType": "rent"
      }]
      this.loading = false
    },
    reset() {
      this.timeFormat = ''
      this.getTableData()
    }
  }
}
</script>

<style lang="scss">
  .statistics-dialog{
    min-height: 400px;
  }
  .el-dialog.is-fullscreen{
    width: 70%;
    height: auto;
    background: #f0f8ff !important;
        position: relative;
        margin: 19px auto;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  }
</style>
    