<template>
  <div class="intelligent-hosing">
    <div style="display: flex;justify-content: space-around;flex-wrap: wrap;">

      <div class="left-card">
        <el-tabs type="border-card">
          <el-tab-pane label="简介">
            <div style="border-bottom: 1px solid #dcdfe6;">
              <div style="margin: 20px 0 ;font-size: 22px;font-weight: 600;">1. 笔数模式</div>
              <ul style="margin: 0 0 20px 20px; color: #909399;font-size: 16px;">
                <li>将委托65000能量到接收地址</li>
                <li>接收地址每USDT转账一次计一笔费用</li>
                <li>24小时内没有转账将重新计1笔费用</li>
                <li>每次USDT转账后能量将在5秒内补充</li>
                <li>地址在委托中重复下将叠加笔数</li>
                <li>笔数模式有两种扣费模式如下：</li>
                <li>第一种需要登录消费一笔扣费一笔</li>
                <li>第二种无需登录一次性付款购买所需笔数，滞留一天算一笔</li>
                <li>通用笔数查询页面（<a href="https://tronscan.tools" style="color:#f56c6c">https://tronscan.tools</a> ）</li>
              </ul>
              <div style="color:#f56c6c;margin-bottom: 20px;font-size: 16px;">
                <span style="font-weight: 600;">注意： </span>当地址少于0.35TRX且带宽不足345将赠送0.35TRX以避免USDT转账卡壳
              </div>
            </div>

            <div style="border-bottom: 1px solid #dcdfe6;">
              <div style="margin: 20px 0 ;font-size: 22px;font-weight: 600;">2. 智能模式</div>
              <ul style="margin: 0 0 20px 20px; color: #909399;font-size: 16px;">
                <li>接收地址少于设定的值将自动委托差量或设定的数量</li>
                <li>委托的时间：1小时，1天，3天</li>
              </ul>
            </div>

            <div style="color:#f56c6c;margin: 20px 0;font-size: 16px;">
              <span style="font-weight: 600;">注意： </span>保持账户余额充足，否则将自动停止地址托管
            </div>
          </el-tab-pane>

          <el-tab-pane label="创建智能托管">
            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;">计价</div>
              <div>每次触发委托跟随系统每笔单价计费，当前一笔<span style="color: #e6a23c;font-size: 20px;margin: 0px 5px;">2.3TRX</span></div>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">模式</div>
              <el-radio-group class="order-type-group" style="margin: 0;" v-model="mode">
                <el-radio-button style="width: 50%" label="1">笔数模式</el-radio-button>
                <el-radio-button style="width: 50%" disabled label="2">智能模式</el-radio-button>
              </el-radio-group>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">地址数量</div>
              <div style="display: flex;">
                <el-radio style="flex:1;text-align: center;" v-model="addressNum" label="1" border size="medium">单条</el-radio>
                <el-radio style="flex:1;text-align: center;"v-model="addressNum" label="2" border size="medium">批量（仅登录可用）</el-radio>
              </div>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">接收地址</div>
              <el-input v-if="addressNum === '1'" v-model="address" placeholder="请填写接收地址" style="width: 100%;"></el-input>
              <el-input v-else type="textarea" :rows="3" placeholder="请输入批量提交的地址,用分号分隔。如：TQE267x5xy94T4UnTwaEZbNm3t1FbTsqHZ;TQE267x5xy94T4UnTwaEZbNm3t1FbTsqHZ;TQE267x5xy94T4UnTwaEZbNm3t1FbTsqHZ" v-model="address"></el-input>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">扣费模式</div>
              <div style="display: flex;">
                <el-radio style="flex:1;text-align: center;" v-model="payMode" label="1" border size="medium">购买笔数</el-radio>
                <el-radio style="flex:1;text-align: center;"v-model="payMode" label="2" border size="medium">账号代扣</el-radio>
              </div>
            </div>

            <template v-if="payMode === '1'">
              <div style="margin-bottom: 22px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">购买委托笔数
                  <el-tooltip class="item" effect="dark" content="到将购买委托笔数自动停止" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-input v-model="entrustNum" placeholder="请填写购买委托笔数" style="width: 100%;"></el-input>
              </div>
            </template>

            <template v-else>
              <div style="margin-bottom: 22px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">委托笔数上限
                  <el-tooltip class="item" effect="dark" content="到将委托笔数上限自动停止" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-input v-model="entrustMax" placeholder="请填写委托笔数上线" style="width: 100%;"></el-input>
              </div>
              <div style="margin-bottom: 22px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">智能委托停止时间
                  <el-tooltip class="item" effect="dark" content="智能委托停止时间" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-date-picker v-model="stopEntrustMaxTime" type="date" placeholder="请选择智能委托停止时间" style="width: 100%;"></el-date-picker>
              </div>
            </template>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">订单备注</div>
              <el-input v-model="orderDesc" placeholder="请填写订单备注，选填" style="width: 100%;"></el-input>
            </div>

            <div style="margin-bottom: 22px;">
              <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">详细</div>
              <div style="line-height: 25px;">
                <label class="left">地址数量：</label>
                <span style="color:#e6a23c">0 个</span>
              </div>
              <div style="line-height: 25px;">
                <label class="left">预计消费：</label>
                <span style="color:#e6a23c">0 TRX</span>
              </div>
              <div style="line-height: 25px;">
                <label class="left">账号余额：</label>
                <span style="color:#e6a23c">50 TRX</span>
              </div>
            </div>

            <el-button v-if="payMode === '2'" type="primary" round style="width: 100%;">创建智能委托</el-button>
            <div v-else style="display: flex;">
              <el-button v-if="addressNum === '1'" type="warning" round style="flex:1">地址支付</el-button>
              <el-button type="success" round style="flex:1" @click="accountPay">账号支付</el-button>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>

      <div class="right-card">
        <el-card>
          <div style="font-size: 22px;font-weight: 600;margin-bottom: 22px">搜索</div>
          <div style="display: flex;justify-content: space-between;align-items: center; gap: 20px;flex-wrap: wrap;margin-bottom: 15px;">
            <div style="display: flex;flex: 1 1 400px;">
              <el-radio style="flex:1;text-align: center;margin-right:20px;" v-model="searchForm.state" label="1" border size="medium" @input="getTableData">委托中</el-radio>
              <el-radio style="flex:1;text-align: center;margin-right:0 20px 0 0 ;"v-model="searchForm.state" label="2" border size="medium" @input="getTableData">停止委托</el-radio>
            </div>
            <div style="display: flex;flex: 1 1 400px;">
              <el-radio style="flex:1;text-align: center;margin-right: 20px;" v-model="searchForm.residueSort" label="1" border size="medium" @input="getTableData">剩余笔数升序</el-radio>
              <el-radio style="flex:1;text-align: center;margin-right:0 20px 0 0 ;"v-model="searchForm.residueSort" label="2" border size="medium" @input="getTableData">剩余笔数降序</el-radio>
            </div>
            <el-input style="flex: 1 1 400px;" v-model="searchForm.receiveAddress" placeholder="请填写接收地址"></el-input>
            <el-button type="warning" round style="flex: 1 1 400px;" @click="resetSearchForm">重置</el-button>
          </div>

          <div v-if="pageSize === 'mini'">
            <div class="order-page-list-item" style="padding: 30px 0 20px 0" v-for="(item, index) in tableData">
              <div class="card-right-index">
                <div style="padding: 0px 10px;">{{ index + 1 }}</div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">状态</div>
                <div class="row-item-right">
                  <el-tag  effect="dark" size="mini" type='primary' v-if="item.state === 1">委托中</el-tag>
                  <el-tag  effect="dark" size="mini" type='error' v-else>停止委托</el-tag>
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">模式</div>
                <div class="row-item-right">
                  <el-tag type='primary' size="mini" style="font-weight: 600;">笔数</el-tag>
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">总消费</div>
                <div class="row-item-right">
                  <el-tag type='warning' size="mini" style="font-weight: 600;">{{ item.totalSun }}</el-tag>
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">监控地址</div>
                <div class="row-item-right">
                  <i @click="copy(item.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                  <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 80px;vertical-align: sub;margin-left: 5px;" class="ellipsis">{{ item.receiveAddress }}</span> 
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">创建时间</div>
                <div class="row-item-right">
                  {{ item.createTime }}
                </div>
              </div>
              <div class="list-row-item" style="display: block;height: auto;">
                <div class="row-item-left">更多</div>
                <div style="display: flex;">
                  <div style="width: 50%;">
                    <p>委托触发条件:{{ item.autoLimitNums }}</p>
                    <p>每笔委托数量:{{ item.everyAutoNums }}</p>
                    <p>累计委托资源:{{ (item.totalResNums / 10000).toFixed(2) }}W</p>
                    <p>滞留计费时间:{{ item.freeze }}</p>
                    <p>笔数扣费模式:购买笔数</p>
                  </div>
                  <div style="min-width: 150px;">
                    <p>剩余委托笔数:{{ item.residue }}</p>
                    <p>累计委托笔数:{{ item.totalDelegateNums }}</p>
                    <p>委托最大笔数:{{ item.maxDelegateNums }}</p>
                    <p>委托终止时间:{{ item.endTime }}</p>
                    <p>购买笔数追加:{{ item.append }}笔</p>
                  </div>
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">详细</div>
                <div class="row-item-right">
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">订单备注</div>
                <div class="row-item-right">
                </div>
              </div>

              <div style="width: 100%;display: flex;margin-top:20px">
                <el-button plain round style="border-color: #409eff;color: #409eff;flex:1;padding:5px" @click="copy(item.orderId)"> 复制订单ID</el-button>
                <el-button plain round @click="openEntrustDialog(item)" style="border-color: #409eff;color: #409eff;flex:1;padding:5px">委托详情</el-button>
              </div>

            </div>
          </div>

          <!-- 表格 -->
          <el-table
            v-else
            :data="tableData"
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="state"
              label="状态"
              width="80">
              <template slot-scope="scope">
                <el-tag  effect="dark" type='primary' v-if="scope.row.state === 1">委托中</el-tag>
                <el-tag  effect="dark" type='error' v-else>停止委托</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="mode"
              label="模式"
              width="80">
              <template slot-scope="scope">
                <el-tag type='primary' style="font-weight: 600;">笔数</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="totalSun"
              label="总消费"
              width="80">
              <template slot-scope="scope">
                <el-tag type='warning' style="font-weight: 600;">{{ scope.row.totalSun }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="receiveAddress"
              label="监控地址"
              width="120">
              <template slot-scope="scope">
                <i @click="copy(scope.row.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 80px;vertical-align: sub;margin-left: 5px;" class="ellipsis">{{ scope.row.receiveAddress }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              width='100'>
            </el-table-column>
            <el-table-column
              label="更多"
              min-width="300">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <div style="margin-right: 10px;min-width: 150px;">
                    <p>委托触发条件:{{ scope.row.autoLimitNums }}</p>
                    <p>每笔委托数量:{{ scope.row.everyAutoNums }}</p>
                    <p>累计委托资源:{{ (scope.row.totalResNums / 10000).toFixed(2) }}W</p>
                    <p>滞留计费时间:{{ scope.row.freeze }}</p>
                    <p>笔数扣费模式:购买笔数</p>
                  </div>
                  <div style="min-width: 150px;">
                    <p>剩余委托笔数:{{ scope.row.residue }}</p>
                    <p>累计委托笔数:{{ scope.row.totalDelegateNums }}</p>
                    <p>委托最大笔数:{{ scope.row.maxDelegateNums }}</p>
                    <p>委托终止时间:{{ scope.row.endTime }}</p>
                    <p>购买笔数追加:{{ scope.row.append }}笔</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="详细"
              width='60'>
            </el-table-column>
            <el-table-column
              label="订单备注"
              width='80'>
            </el-table-column>
            <el-table-column
              label="操作"
              width='215'>
              <template slot-scope="scope">
                <el-button type="primary" round plain style="padding: 5px 12px" @click="copy(scope.row.orderId)">复制订单ID</el-button>
                <el-button type="primary" round plain style="padding: 5px 12px" @click="openEntrustDialog(scope.row)">委托详情</el-button>
                <el-button type="primary" round plain style="padding: 5px 12px;margin: 10px 0 0 0">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <!-- 账号支付dialog -->
    <el-dialog
      :visible.sync="accountPayVisible"
      :append-to-body="true"
      custom-class="pay-path-dialog"
      width="400px">
      <div style="font-size: 36px;font-weight: bold;color:#409eff;padding: 20px 40px">
        <i class="el-icon-loading"></i>
        账户支付中...
      </div>
    </el-dialog>   

    <!-- 委托详情 -->
    <el-dialog
      :title="entrustOrderId"
      :visible.sync="entrustDialogVisible"
      :fullscreen="pageSize === 'mini'"
      custom-class="entrust-dialog"
      :append-to-body="true">
      <div>
        <div v-if="pageSize === 'mini'">
          <el-card class="order-page-list-item" style="padding: 30px 0x 20px 0" v-for="(item, index) in dialogInfo.tableData">
            <div class="card-right-index">
              <div style="padding: 0px 10px;">{{ index + 1 }}</div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">状态</div>
              <div class="row-item-right">
                <el-tag  effect="dark" size="mini" type='primary' v-if="item.state === 1">委托中</el-tag>
                <el-tag  effect="dark" size="mini" type='error' v-else>停止委托</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">模式</div>
              <div class="row-item-right">
                笔数
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">能量数量</div>
              <div class="row-item-right">
                {{ item.delegateNums }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">委托消费</div>
              <div class="row-item-right">
                {{ item.resMoney }}  
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">滞留计费</div>
              <div class="row-item-right">
                {{ item.detentionCharge }}  
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">滞留天数</div>
              <div class="row-item-right">
                {{ item.detentionChargeTime }}  
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">触发合约</div>
              <div class="row-item-right">
                <i @click="copy('triggerFirst')" style="cursor: pointer" class="el-icon-document-copy"></i>
                {{ item.detentionChargeTime }}  
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">开始时间</div>
              <div class="row-item-right">
                {{ item.delegateStart }}  
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">结束时间</div>
              <div class="row-item-right">
                {{ item.delegateEnd }}  
              </div>
            </div>
          </el-card>
        </div>

        <el-table
          v-else
          :data="dialogInfo.tableData"
          style="width: 100%">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            prop="state"
            label="状态">
            <template slot-scope="scope">
              <el-tag  effect="dark" type='primary' v-if="scope.row.state === 1">委托中</el-tag>
              <el-tag  effect="dark" type='error' v-else>停止委托</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="mode"
            label="模式">
            <template slot-scope="scope">
              笔数
            </template>
          </el-table-column>
          <el-table-column
            prop="delegateNums"
            label="能量数量">
          </el-table-column>
          <el-table-column
            prop="resMoney"
            label="委托消费">
          </el-table-column>
          <el-table-column
            prop="detentionCharge"
            label="滞留计费">
          </el-table-column>
          <el-table-column
            prop="detentionChargeTime"
            label="滞留天数">
          </el-table-column>
          <el-table-column
            prop="detentionChargeTime"
            width="140"
            label="触发合约">
              <template slot-scope="scope">
                <i @click="copy(scope.row.triggerTransactionId)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                <span style="display: inline-block;height:20px; margin-left: 5px;" class="ellipsis">首次代理能量</span>
              </template>
          </el-table-column>
          <el-table-column
            prop="delegateStart"
            width="100"
            label="开始时间">
          </el-table-column>
          <el-table-column
            prop="delegateEnd"
            width="100"
            label="结束时间">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> 

  </div>
</template>

<script>

export default {
  name: 'IntelligentHosingView',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    }
  },
  data(){
    return {
      // 模式
      mode: '1',
      // 地址数量
      addressNum: '1',
      // 接收地址
      address: '',
      // 扣费模式
      payMode: '1',
      // 委托笔数
      entrustNum: '',
      // 订单备注
      orderDesc: '',
      // 委托笔数上线
      entrustMax: '',
      // 智能停止委托时间
      stopEntrustMaxTime: '',
      // 账号支付提示弹窗
      accountPayVisible: false,
      tableData: [],
      getDataInterval: null,
      // 右侧搜索条件
      searchForm: {
        state: '', //委托中/停止委托
        // 剩余笔数升序/降序: '',
        residueSort: '',
        receiveAddress: '' // 接收地址
      },
      // 委托详情 dialog
      entrustOrderId: '',
      entrustDialogVisible: false,
      dialogInfo: {
        tableData: []
      }
    }
  },
  methods: {
    accountPay() {
      this.accountPayVisible = true
    },
    // 复制内容
    copy(info){
      const input = document.createElement("input"); // 创建输入框元素   
      input.value = info; // 设置输入框的值为要复制的内容
      document.body.appendChild(input); // 将输入框添加到页面中
      input.select(); // 选中输入框中的内容
      if (document.execCommand('copy')) { // 如果复制成功
        this.$message({
          message: '复制成功',
          type: 'success',
          duration: 1500
        });
      } else { this.$message({
          message: '复制失败',
          type: 'error',
          duration: 1500
        });
      }
      document.body.removeChild(input); // 移除输入框
    },
    // 委托详情
    openEntrustDialog(row){
      this.entrustOrderId = row.orderId +''
      //todo 调用api接口
      const data = {
        "data": [
          {
            "orderId": 1709451105850458,
            "state": 1,
            "autoType": 0,
            "delegateNums": "6.5W",
            "rentTime": "1天",
            "triggerTransactionId": "triggerFirst",
            "resMoney": "2.3个",
            "detentionCharge": "0个",
            "detentionChargeTime": 0,
            "delegateStart": "2024-03-03 15:31:46",
            "delegateEnd": "2024-03-04 15:31:46"
          }
        ],
        "pages": 1,
        "residue": 1,
        "payment": 1,
        "orderId": 1709451105850458,
        "receiveAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm",
        "maxNums": 1,
        "detentionChargeTime": 0,
        "totalDelegateNums": 0
      }
      this.dialogInfo.tableData = data.data

      this.entrustDialogVisible = true
    },
    // 获取右侧表格数据
    getTableData() {
      // 拼装搜索条件
      // 调用api 结果赋值
      this.tableData = [
        {
          "orderId": 1709451105850458,
          "receiveAddress": "TYUhqB42btjzGt7TJcoDpt3vwXMMMmmmmm",
          "autoType": 0,
          "autoLimitNums": 65000,
          "everyAutoNums": 65000,
          "state": 1,
          "endTime": "2024-12-31",
          "maxDelegateNums": 1,
          "totalDelegateNums": 1,
          "detentionChargeTime": 0,
          "totalSun": "2.3TRX",
          "totalResNums": 65000,
          "info": "",
          "freeze": 0,
          "oneTimePayment": 2300000,
          "createTime": "2024-03-03 15:31:45",
          "append": 0,
          "residue": 0,
          "payment": true,
          "orderNotes": ""
        }
      ]
    },
    // 重置右侧搜索条件
    resetSearchForm() {
      this.searchForm = {
        state: '', //委托中/停止委托
        // 剩余笔数升序/降序: '',
        residueSort: '',
        receiveAddress: '' // 接收地址
      }
      // 重新获取
      this.getTableData()
    },
  },
  mounted() {
    this.getTableData()
    // 6s重新获取一次
    this.getDataInterval = setInterval(this.getTableData, 6000)
  },
  destroyed() {
    clearInterval(this.getDataInterval)
  }
}
</script>

<style scoped lang="scss">
  .intelligent-hosing{
    text-align: left;
    font-size: 14px;;
    .left-card{
      flex: 1;
      flex-basis: 400px;
      margin: 15px
    }

    .right-card{
      flex: 2;
      // flex-basis: 800px;
      margin: 15px;
      .el-card{
        height: calc(100%);
      }
    }
  }
</style>
<style lang="scss">
.entrust-dialog{
  .el-dialog__header {
    border-bottom: 1px solid #e5e7eb;
  }
  &.is-fullscreen {
    background-color: rgb(240, 248, 255) !important;
    .el-dialog__header{
      background-color: white;
    }
    
  }
}
</style>