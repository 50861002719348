<template>
  <!-- 智能订单 编辑 -->
  <el-dialog
    title="智能速充参数配置"
    :visible.sync="dialogVisible"
    custom-class="auto-order-dialog"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :append-to-body="true">
    <div style="margin-bottom: 22px;">
      <el-divider content-position="left">能量</el-divider>
      <el-switch
        v-model="form.energyState"
        active-text="自动速充开"
        inactive-text="自动速充关"
        active-color="#13ce66"
        :active-value=1
        :inactive-value=0
        style="margin: 15px 0">
      </el-switch>

      <div style="margin-bottom: 15px;">
        <div style="line-height: 25px;">
          请填写触发速充的条件，地址小于该值自动速充，不能小于<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">10</span>万
        </div>
        <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="form.energyAutoLimitNums" clearable></el-input>
      </div>

      <div style="margin-bottom: 15px;">
        <div style="line-height: 25px;">
          请填写自动速充数量的有效范围。至少<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">10</span>万，
          至多<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">100</span>万
        </div>
        <div style="display: flex; gap: 15px; flex-wrap: wrap;">
          <el-input placeholder="1000000" disabled style="flex: 1 1 300px">
            <template slot="prepend">至少：</template>
          </el-input>
          <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="form.energyMax" style="flex: 1 1 300px">
            <template slot="prepend">至多：</template>
          </el-input>
        </div>
      </div>

      <el-divider content-position="left" style="margin-top: 30px">带宽</el-divider>
      <el-switch
          v-model="form.bandwidthState"
          active-text="自动速充开"
          inactive-text="自动速充关"
          active-color="#13ce66"
          :active-value=1
          :inactive-value=0
          style="margin: 15px 0">
      </el-switch>

      <div style="margin-bottom: 15px;">
        <div style="line-height: 25px;">
          请填写触发速充的条件，地址小于该值自动速充，不能小于<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">0.5</span>万
        </div>
        <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于0.5万" v-model="form.bandwidthAutoLimitNums" clearable></el-input>
      </div>

      <div style="margin-bottom: 15px;">
        <div style="line-height: 25px;">
          请填写自动速充数量的有效范围。至少<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">2</span>万，
          至多<span style="color: #f56c6c;font-weight: 600;font-size: 15px;margin: 0 5px;">2</span>万
        </div>
        <div style="display: flex; gap: 15px; flex-wrap: wrap;">
          <el-input placeholder="2000000" disabled style="flex: 1 1 300px">
            <template slot="prepend">至少：</template>
          </el-input>
          <el-input placeholder="请填写触发速充的条件，地址小于该值自动速充，不能小于10万" v-model="form.bandwidthMax" style="flex: 1 1 300px">
            <template slot="prepend">至多：</template>
          </el-input>
        </div>
      </div>
      <el-button type="primary" round style="width: 100%;margin-top: 10px;" @click="sbumit">保存</el-button>
    </div>
  </el-dialog>
</template>
  
<script>
  export default {
    name: 'UserSpeedView',
    props: {
      pageSize: {
        type: String, 
        default: 'medium'
      },
      dialogVisible: {
        type: Boolean,
        default: false
      },
      orderForm: {
        type: Object,
        default: {}
      }
    },
    data(){
      return {
        form: {
          orderId: '',
          energyState: false,
          energyAutoLimitNums: 100000,
          energyMin: 1000000,
          energyMax: 1000000,
          bandwidthState: false,
          bandwidthAutoLimitNums: 5000,
          bandwidthMin: 20000,
          bandwidthMax: 20000
        }
      }
    },
    methods: {
      sbumit() {
        // console.log(this.form)

        this.$emit('update:dialogVisible', false)
      }
    },
    watch: {
      orderForm: {
        handler: function(newVal){
          const {
            orderId,
            energyState,
            energyAutoLimitNums,
            energyMin,
            energyMax,
            bandwidthState,
            bandwidthAutoLimitNums,
            bandwidthMin,
            bandwidthMax
          } = newVal
          this.form = Object.assign(this.form, {
            orderId,
            energyState,
            energyAutoLimitNums,
            energyMin,
            energyMax,
            bandwidthState,
            bandwidthAutoLimitNums,
            bandwidthMin,
            bandwidthMax
          } )
        },
        deep: true
      }
    }
  }
</script>
  
<style lang="scss">

</style>