<template>
  <!-- 消费记录 -->
  <el-dialog
    :title="orderId+'--能量'"
    :visible="orderRecordDialogVisible"
    custom-class="order-record-dialog"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :append-to-body="true"
    :before-close="close">
    <div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          label="订单类型">
          <template slot-scope="scope">
            <el-tag  effect="dark" type='primary'>下单</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="payNums"
          label="租用数量">
          <template slot-scope="scope">
            {{ scope.row.payNums/10000 }}W
          </template>
        </el-table-column>
        <el-table-column
          prop="rentTime"
          label="租用时长">
        </el-table-column>
        <el-table-column
          prop="orderPrice"
          label="订单单价">
        </el-table-column>
        <el-table-column
          prop="orderMoney"
          label="消费金额">
          <template slot-scope="scope">
            {{ scope.row.payNums/10000 }} trx
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="起始时间">
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="回收时间">
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
  
<script>
  export default {
    name: 'UserSpeedView',
    props: {
      pageSize: {
        type: String, 
        default: 'medium'
      },
      dialogVisible: {
        type: Boolean,
        default: false
      },
      orderId: {
        type: String,
        default: ''
      }
    },
    data(){
      return {
        orderRecordDialogVisible: false,
        tableData: [
          {
            "orderMoney": 2560000,
            "payNums": 32000,
            "orderPrice": 80,
            "rentTime": "1天",
            "startTime": "2024-03-07 21:28:46",
            "endTime": "2024-03-08 21:28:46",
            "firstOrder": 1
          }
        ]
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      getTableData(orderId) {

      },
      sbumit() {
        // console.log(this.form)

        this.close()
      }
    },
    watch: {
      orderId: {
        handler: function(newVal){
          // 获取消费记录
          this.getTableData(newVal)
        }
      },
      dialogVisible: function(newVal) {
        this.orderRecordDialogVisible = newVal
      }
    }
  }
</script>
  
<style lang="scss">

</style>