<template>
  <!-- 代理详情 -->
  <el-dialog
    title="代理详情"
    :visible.sync="dialogVisible"
    custom-class="agent-detail-dialog"
    :close-on-click-modal="false"
    :fullscreen="pageSize === 'mini'"
    :append-to-body="true"
    :before-close="close">
    <div>
      <el-card>
        <div style="background-color: #ecf5ff;border-radius: 5px;border-color: #d9ecff;padding: 15px 20px;">
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">资源类型</span>
            <span  class="middle">:</span>
            <span  class="right phone-right primary-color" style="font-weight: 600;">{{ agentInfo.orderType === 'ENERGY' ? '能量' : '' }}</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">订单状态</span>
            <span  class="middle">:</span>
            <span  class="right phone-right primary-color" style="font-weight: 600;">{{ agentInfo.state }}</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">资源锁定</span>
            <span  class="middle">:</span>
            <span  class="right phone-right primary-color" style="font-weight: 600;">
              <el-switch
                disabled
                v-model="agentInfo.rentTimeLock"
                active-color="#13ce66"
                :active-value=1
                :inactive-value=0>
              </el-switch>
            </span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">订单总量</span>
            <span class="middle">:</span>
            <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentInfo.payNums/10000 }}W</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">代理成功数量</span>
            <span class="middle">:</span>
            <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentInfo.payNums/10000 }}W</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">代理失败数量</span>
            <span class="middle">:</span>
            <span class="right phone-right primary-color" style="font-weight: 600;color:#f56c6c">0</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">接收资源地址</span>
            <span class="middle">:</span>
            <span class="right phone-right primary-color ellipsis" style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 100px;">{{ agentInfo.receiveAddress }}</span>
          </div>
          <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
            <span class="left" style="width: 120px;">订单备注</span>
            <span class="middle">:</span>
            <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentInfo.orderNotes }}</span>
          </div>
          
        </div>
        <div style="text-align: left;margin-top: 0px;    color: #f56c6c !important;">
          温馨提示：异常的代理可以复制交易ID，联系客服
        </div>
      </el-card>

      <div v-if="pageSize === 'mini'">
        <div style="position: relative;margin-top: 50px" v-for="(item, index) in agentInfo.hash">
          <div class="mini-tip" style="width:60px">订单正常</div>
          <div class="card-right-index" style="z-index:2">
            <div style="padding: 0px 10px;">{{ index + 1 }}</div>
          </div>
          <el-card class="order-page-list-item" >
            <div class="list-row-item">
              <div class="row-item-left">发送资源地址</div>
              <div class="row-item-right">
                <i @click="copy(item.sendAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                <span style="display: inline-block;height:20px; width: 80px;margin-left: 5px;" class="ellipsis">{{ item.sendAddress }}</span>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">信息</div>
              <div class="row-item-right">
                {{ item.info }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">类型</div>
              <div class="row-item-right">
                {{ item.resType === 'ENERGY' ? '能量' : '' }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">租用时长</div>
              <div class="row-item-right">
                {{ item.rentTime }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">代理数量</div>
              <div class="row-item-right">
                {{ item.resNums/10000 }}W
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">回收时间</div>
              <div class="row-item-right">
                {{ item.recoveryTime }}
              </div>
            </div>
            <div style="width: 100%;display: flex;margin-top:20px">
              <el-button plain round style="border-color: #409eff;color: #409eff;flex:1;padding:5px">交易Hash</el-button>
              <el-button plain round @click="copy(item.orderId)" style="border-color: #409eff;color: #409eff;flex:1;padding:5px">复制交易ID</el-button>
              <el-button plain round @click="copy(scope.row.orderId)" style="border-color: #409eff;color: #409eff;flex:1;padding:5px">订单ID</el-button>
              <el-button plain round @click="orderRenew(item.orderId)" style="border-color: #409eff;color: #409eff;flex:1;padding:5px">订单续费</el-button>
            </div>
          </el-card>
        </div>
      </div>

      <el-table
        v-else
        :data="agentInfo.hash"
        style="width: 100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="state"
          width="100"
          label="状态">
          <template slot-scope="scope">
            <el-tag  effect="dark" type='success'>{{scope.row.state}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="sendAddress"
          width="150"
          label="发送资源地址">
          <template slot-scope="scope">
            <i @click="copy(scope.row.sendAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
            <span style="display: inline-block;height:20px; width: 80px;margin-left: 5px;" class="ellipsis">{{ scope.row.sendAddress }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="info"
          label="信息">
        </el-table-column>
        <el-table-column
          prop="orderPrice"
          label="类型">
          <template slot-scope="scope">
            <span class="right phone-right primary-color">{{ scope.row.resType === 'ENERGY' ? '能量' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="rentTime"
          label="租用时长">
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="代理数量">
          <template slot-scope="scope">
            {{ scope.row.resNums/10000 }}W
          </template>
        </el-table-column>
        <el-table-column
          prop="recoveryTime"
          width="100"
          label="回收时间">
        </el-table-column>
        <el-table-column
          width="200"
          label="其他">
          <template slot-scope="scope">
            <el-button plain round style="border-color: #dcdfe6;color: #606266;padding: 5px;flex:1">交易Hash</el-button>
            <el-button plain round @click="copy(scope.row.orderId)" style="border-color: #dcdfe6;color: #606266;padding: 5px;flex:1">复制交易ID</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
  
<script>
import { copy } from '@/utils/tools.js'
export default {
  name: 'UserSpeedView',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    agentInfo: {
      type: Object,
      default: ()=> {
        return {
          hash: []
        }
      }
    }
  },
  data(){
    return {
      
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    sbumit() {
      // console.log(this.form)

      this.close()
    },
    copy
  },
  watch: {
    orderId: {
      handler: function(newVal){
        // 获取消费记录
        this.getTableData(newVal)
      }
    }
  }
}
</script>
  
<style lang="scss">

</style>