import Vue from 'vue'
import VueRouter from 'vue-router'
import AppView from '../App.vue'
import MainView from '../views/main.vue'
import HomeView from '../views/HomeView'
import OrderView from '../views/OrderView'
import PayView from '../views/PayView'
import IntelligentHosingView from '../views/IntelligentHosingView'
import TransferRentView from '../views/TransferRentView.vue'
import InterfaceView from '../views/InterfaceView'
import UserHomeView from "../views/userMore/UserHomeView.vue"
import UserSpeedView from "../views/userMore/UserSpeedView.vue"
import UserOrderView from "../views/userMore/UserOrderView.vue"
import BalanceRecordView from "../views/userMore/BalanceRecordView.vue"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    component: MainView,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component:  HomeView
      },
      {
        path: 'order',
        name: 'order',
        component:  OrderView
      },
      {
        path: 'pay',
        name: 'pay',
        component:  PayView
      },
      {
        path: 'intelligentHosing',
        name: 'intelligentHosing',
        component:  IntelligentHosingView
      },
      {
        path: 'transferRent',
        name: 'transferRent',
        component:  TransferRentView
      },
      {
        path: 'interface',
        name: 'interface',
        component:  InterfaceView
      },
      {
        path: 'userHome',
        name: 'userHome',
        component:  UserHomeView
      },
      {
        path: 'userSpeed',
        name: 'userSpeed',
        component:  UserSpeedView
      },
      {
        path: 'userOrder',
        name: 'userOrder',
        component:  UserOrderView
      },
      {
        path: 'balanceRecord',
        name: 'balanceRecord',
        component:  BalanceRecordView
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
