<template>
  <div class="order">
    <el-card class="box-card">
      <div v-if="pageSize === 'mini'">
        <div class="order-page-list-item" v-for="(item, index) in tableData" :key="index">
          <div class="card-right-index">
            <div class="phone-index"> 即将回收 </div>
            <div style="padding: 0px 10px;">{{ index + 1 }}</div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">资源锁定</div>
            <div class="row-item-right">
              <el-switch
                disabled
                v-model="item.rentTimeLock"
                active-color="#13ce66"
                :active-value=1
                :inactive-value=0>
              </el-switch>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">资源类型</div>
            <div class="row-item-right">
              <el-tag size="mini" type='danger' style="font-weight: 600;">{{ item.orderType === 'ENERGY' ? '能量' : '' }}</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">租用时间</div>
            <div class="row-item-right">
              <el-tag size="mini" type='primary' style="font-weight: 600;">{{ item.lockTime }}</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">完成数量</div>
            <div class="row-item-right">
              <el-tag size="mini" type='warning' style="font-weight: 600;">{{ item.payNums/10000 }}W</el-tag>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">接收地址</div>
            <div class="row-item-right">
              <i @click="copy(item.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
              <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 80px;margin-left: 5px;" class="ellipsis">{{ item.receiveAddress }}</span>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">消费TRX</div>
            <div class="row-item-right">
              <span style="color:#409eff;font-weight:600;">{{ item.orderMoney }}</span>
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">创建时间</div>
            <div class="row-item-right">
              {{item.time}}
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">回收时间</div>
            <div class="row-item-right">
              {{item.recoveryTime}}
            </div>
          </div>
          <div class="list-row-item">
            <div class="row-item-left">订单进度</div>
            <div class="row-item-right">
            </div>
          </div>
          <!-- 步骤 -->
          <el-steps size="mini" :active="item.orderState" process-status="success" finish-status="success" style="margin: 5px 0 10px 0">
            <el-step title="安排中"></el-step>
            <el-step title="部分代理"></el-step>
            <el-step title="代理中"></el-step>
            <el-step title="已回收部分"></el-step>
            <el-step title="已回收"></el-step>
          </el-steps>
          
          <div class="list-row-item">
            <div class="row-item-left">订单拓展</div>
            <div class="row-item-right">
            </div>
          </div>
          <div style="display: flex;">
            <el-button plain round @click="orderRecord(item)" style="border-color: #409eff;color: #409eff;padding: 5px;flex:1">消费记录</el-button>
            <el-button plain round @click="agentInfo(item)" style="border-color: #409eff;color: #409eff;padding: 5px;flex:1">代理详情</el-button>
          </div>
        </div>
      </div>

      <el-table
        v-else
        v-loading="loading"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="state"
          label="订单进度"
          width="130">
          <template slot-scope="scope">
            <el-tag  effect="dark" type='primary'>{{ scope.row.state }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="rentTimeLock"
          label="资源锁定">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.rentTimeLock"
              active-color="#13ce66"
              :active-value=1
              :inactive-value=0>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderType"
          label="资源类型">
          <template slot-scope="scope">
            <el-tag type='danger' style="font-weight: 600;">{{ scope.row.orderType === 'ENERGY' ? '能量' : '' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="lockTime"
          label="租用时间">
          <template slot-scope="scope">
            <el-tag type='primary' style="font-weight: 600;">{{ scope.row.lockTime }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="payNums"
          label="完成数量">
          <template slot-scope="scope">
            <el-tag type='warning' style="font-weight: 600;">{{ scope.row.payNums/10000 }}W</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="receiveAddress"
          label="接收地址"
          width='120'>
          <template slot-scope="scope">
            <i @click="copy(scope.row.receiveAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
            <span style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 80px;vertical-align: sub;margin-left: 5px;" class="ellipsis">{{ scope.row.receiveAddress }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderMoney"
          label="消费TRX">
          <template slot-scope="scope">
            <span style="color:#409eff;font-weight:600;">{{ scope.row.orderMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="创建时间"
          width='100'>
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="recoveryTime"
          label="回收时间"
          width='130'>
          <template slot-scope="scope">
            <div style="padding: 5px 18px;background-color: #f56c6c;border-radius: 5px;color: white;font-weight: 600;text-align: 14px;line-height: 18px">{{ scope.row.recoveryTime }}<br>即将回收</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="recoveryTime"
          width="260"
          label="订单拓展">
          <template slot-scope="scope">
            <el-button type="primary" plain round @click="orderRecord(scope.row)">消费记录</el-button>
            <el-button type="primary" plain round @click="agentInfo(scope.row)">代理详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
   
    <!-- 消费记录 -->
    <el-dialog
      :title="dialogInfo.title"
      :visible.sync="orderRecordDialogVisible"
      :fullscreen="pageSize === 'mini'"
      custom-class="order-record-dialog"
      :close-on-click-modal="false"
      :append-to-body="true">
      <div>
        <div v-if="pageSize === 'mini'">
          <div class="order-page-list-item" style="padding: 30px 20px 20px 20px" v-for="(item, index) in dialogInfo.tableData">
            <div class="mini-tip">下单</div>
            <div class="card-right-index">
              <div style="padding: 0px 10px;">{{ index + 1 }}</div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">租用数量</div>
              <div class="row-item-right">
                {{ item.payNums/10000 }}W
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">租用时长</div>
              <div class="row-item-right">
                {{ item.rentTime }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">订单单价</div>
              <div class="row-item-right">
                {{item.orderPrice}}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">消费金额</div>
              <div class="row-item-right">
                {{ item.payNums/10000 }} trx
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">起始时间
                <el-tooltip class="item" effect="dark" content="回收时间仅作为参考值，具体回收时间由代理交易hash为准" placement="bottom">
                  <i class="el-icon-question" style="color:#409eff"></i>
                </el-tooltip>
              </div>
              <div class="row-item-right">
                {{ item.startTime }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">回收时间
                <el-tooltip class="item" effect="dark" content="回收时间仅作为参考值，具体回收时间由代理交易hash为准" placement="bottom">
                  <i class="el-icon-question" style="color:#409eff"></i>
                </el-tooltip>
              </div>
              <div class="row-item-right">
                {{ item.endTime }}
              </div>
            </div>
          </div>
        </div>

        <el-table
          v-else
          :data="dialogInfo.tableData"
          style="width: 100%">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            label="订单类型">
            <template slot-scope="scope">
              <el-tag  effect="dark" type='primary'>下单</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="payNums"
            label="租用数量">
            <template slot-scope="scope">
              {{ scope.row.payNums/10000 }}W
            </template>
          </el-table-column>
          <el-table-column
            prop="rentTime"
            label="租用时长">
          </el-table-column>
          <el-table-column
            prop="orderPrice"
            label="订单单价">
          </el-table-column>
          <el-table-column
            prop="orderMoney"
            label="消费金额">
            <template slot-scope="scope">
              {{ scope.row.payNums/10000 }} trx
            </template>
          </el-table-column>
          <el-table-column
            prop="startTime"
            label="起始时间">
          </el-table-column>
          <el-table-column
            prop="endTime"
            label="回收时间">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> 
      
     <!-- 代理详情 -->
    <el-dialog
      title="代理详情"
      :visible.sync="agentDialogVisible"
      :close-on-click-modal="false"
      :fullscreen="pageSize === 'mini'"
      custom-class="order-agent-dialog"
      :append-to-body="true">
      <div>
        <el-card>
          <div style="background-color: #ecf5ff;border-radius: 5px;border-color: #d9ecff;    padding: 15px 20px;">
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">资源类型</span>
              <span  class="middle">:</span>
              <span  class="right phone-right primary-color" style="font-weight: 600;">{{ agentDialogInfo.orderType === 'ENERGY' ? '能量' : '' }}</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">订单状态</span>
              <span  class="middle">:</span>
              <span  class="right phone-right primary-color" style="font-weight: 600;">{{ agentDialogInfo.state }}</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">资源锁定</span>
              <span  class="middle">:</span>
              <span  class="right phone-right primary-color" style="font-weight: 600;">
                <el-switch
                  disabled
                  v-model="agentDialogInfo.rentTimeLock"
                  active-color="#13ce66"
                  :active-value=1
                  :inactive-value=0>
                </el-switch>
              </span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">订单总量</span>
              <span class="middle">:</span>
              <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentDialogInfo.payNums/10000 }}W</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">代理成功数量</span>
              <span class="middle">:</span>
              <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentDialogInfo.payNums/10000 }}W</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">代理失败数量</span>
              <span class="middle">:</span>
              <span class="right phone-right primary-color" style="font-weight: 600;color:#f56c6c">0</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">接收资源地址</span>
              <span class="middle">:</span>
              <span class="right phone-right primary-color ellipsis" style="color:#409eff;font-weight:600;display: inline-block;height:20px; width: 100px;">{{ agentDialogInfo.receiveAddress }}</span>
            </div>
            <div class="flex-align-center" style="margin: 4px 0px; padding: 0px;">
              <span class="left" style="width: 120px;">订单备注</span>
              <span class="middle">:</span>
              <span class="right phone-right primary-color" style="font-weight: 600;">{{ agentDialogInfo.orderNotes }}</span>
            </div>

          </div>
          <div style="text-align: left;margin-top: 0px;    color: #f56c6c !important;">
            温馨提示：异常的代理可以复制交易ID，联系客服
          </div>
        </el-card>

        <div v-if="pageSize === 'mini'">
          <div style="position: relative;margin-top: 50px" v-for="(item, index) in agentDialogInfo.hash">
            <div class="mini-tip" style="width:60px">订单正常</div>
            <div class="card-right-index" style="z-index:2">
              <div style="padding: 0px 10px;">{{ index + 1 }}</div>
            </div>
            <el-card class="order-page-list-item" >
              <div class="list-row-item">
                <div class="row-item-left">发送资源地址</div>
                <div class="row-item-right">
                  <i @click="copy(item.sendAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
                  <span style="display: inline-block;height:20px; width: 80px;margin-left: 5px;" class="ellipsis">{{ item.sendAddress }}</span>
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">信息</div>
                <div class="row-item-right">
                  {{ item.info }}
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">类型</div>
                <div class="row-item-right">
                  {{ item.resType === 'ENERGY' ? '能量' : '' }}
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">租用时长</div>
                <div class="row-item-right">
                  {{ item.rentTime }}
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">代理数量</div>
                <div class="row-item-right">
                  {{ item.resNums/10000 }}W
                </div>
              </div>
              <div class="list-row-item">
                <div class="row-item-left">回收时间</div>
                <div class="row-item-right">
                  {{ item.recoveryTime }}
                </div>
              </div>
              <div style="width: 100%;display: flex;margin-top:20px">
                <el-button plain round style="border-color: #409eff;color: #409eff;flex:1;padding:5px">交易Hash</el-button>
                <el-button plain round @click="copy(item.orderId)" style="border-color: #409eff;color: #409eff;flex:1;padding:5px">复制交易ID</el-button>
              </div>
            </el-card>
          </div>
        </div>
        <el-table
          v-else
          :data="agentDialogInfo.hash"
          style="width: 100%">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            prop="state"
            width="100"
            label="状态">
            <template slot-scope="scope">
              <el-tag  effect="dark" type='success'>{{scope.row.state}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="sendAddress"
            width="150"
            label="发送资源地址">
            <template slot-scope="scope">
              <i @click="copy(scope.row.sendAddress)" style="cursor: pointer; color:#409eff" class="el-icon-document-copy"></i>
              <span style="display: inline-block;height:20px; width: 80px;margin-left: 5px;" class="ellipsis">{{ scope.row.sendAddress }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="info"
            label="信息">
          </el-table-column>
          <el-table-column
            prop="orderPrice"
            label="类型">
            <template slot-scope="scope">
              <span class="right phone-right primary-color">{{ scope.row.resType === 'ENERGY' ? '能量' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="rentTime"
            label="租用时长">
          </el-table-column>
          <el-table-column
            prop="startTime"
            label="代理数量">
            <template slot-scope="scope">
              {{ scope.row.resNums/10000 }}W
            </template>
          </el-table-column>
          <el-table-column
            prop="recoveryTime"
            width="100"
            label="回收时间">
          </el-table-column>
          <el-table-column
            width="200"
            label="其他">
            <template slot-scope="scope">
              <el-button plain round style="border-color: #409eff;color: #409eff;padding: 5px;flex:1">交易Hash</el-button>
              <el-button plain round @click="copy(scope.row.orderId)" style="border-color: #409eff;color: #409eff;padding: 5px;flex:1">复制交易ID</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> 
  </div>
</template>
<script>

export default {
  name: 'OrderView',
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    }
  },
  data () {
    return {
      loading: false,
      tableData: [],
      orderRecordDialogVisible: false,
      dialogInfo: {
        title: '',
        tableData: []
      },
      getDataInterval: null,
      agentDialogVisible: false,
      agentDialogInfo: {}

    }
  },
  methods: {
    // 获取页面表格数据
    getTableData() {
      console.log('getTableData')
      this.loading = true
      // todo 从api请求数据
      this.tableData = [
        {
          "orderId": "1709377340160249",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:21",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:20",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "TBvHJ1GM5nkEa3fBJg37AT1JJRtN93cHWM",
          orderState: 2,
          hash: [
            {
              "state": "订单正常",
              "orderId": "389f480f1521b30b",
              "resType": "ENERGY",
              "rentTime": " 1时",
              "resNums": 32000,
              "recoveryTime": "2024-03-07 21:17:54",
              "hash": "41e25422e7f283300351542c5e8a33e3d39aaafb83b3f0d55523c1a6f308a249",
              "sendAddress": "TUVkhpjjahg1b9UjbJzmGBZboDvSXQcrAU",
              "sendResourceResult": 1,
              "recoveryHash": "",
              "info": "代理成功"
            }
          ]
        },
        {
          "orderId": "1709377339971625",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:21",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:19",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "TRJAP2BwULjwJpxK5XgPq4zvVYYScYa4Gx",
          orderState: 2
        },
        {
          "orderId": "1709377339735580",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:20",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:19",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "TJR6tTaSCF9GRoJj9VbmFh6yr7K7QCn27L",
          orderState: 2
        },
        {
          "orderId": "1709377338778617",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:19",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:18",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "TLpLrgeETB8xcjmmxsJu8mpVpPG1uvW48y",
          orderState: 2
        },
        {
          "orderId": "1709377336175527",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:17",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:16",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "TBg2UfhbPXDoPajJXuBHHXXsKJMNSED8Up",
          orderState: 2
        },
        {
          "orderId": "1709377335886846",
          "orderType": "ENERGY",
          "rentTimeLock": 0,
          "recoveryTime": "2024-03-02 20:03:16",
          "state": "代理中",
          "canRenewals": true,
          "payNums": 32100,
          "price": "",
          "orderMoney": "1.38 TRX",
          "lockTime": " 1时",
          "time": "2024-03-02 19:02:15",
          "orderNotes": "",
          "transactionId": "",
          "receiveAddress": "THuEM8TudwP3jsMDzZ3H2Qkfwhwbsfmfki",
          orderState: 2
        }
      ]
      this.loading = false
    },
    // 复制接收地址
    copy(mesg){
      const input = document.createElement("input"); // 创建输入框元素   
      input.value = mesg; // 设置输入框的值为要复制的内容
      document.body.appendChild(input); // 将输入框添加到页面中
      input.select(); // 选中输入框中的内容
      if (document.execCommand('copy')) { // 如果复制成功
        this.$message({
          message: '复制成功',
          type: 'success',
          duration: 1500
        });
      } else { this.$message({
          message: '复制失败',
          type: 'error',
          duration: 1500
        });
      }
      document.body.removeChild(input); // 移除输入框
    },
    // 消费记录
    orderRecord(row) {
      const { orderId } = row
      this.dialogInfo.title = `${orderId}--能量`
      this.orderRecordDialogVisible = true
      // todo 调用api
      const list = [
        {
          "orderMoney": 1380000,
          "payNums": 32000,
          "orderPrice": 43,
          "rentTime": " 1时",
          "startTime": "2024-03-02 19:36:43",
          "endTime": "2024-03-02 20:36:43",
          "firstOrder": 1
        }
      ]
      this.dialogInfo.tableData = list

    },
    // 代理详情
    agentInfo(row) {
      this.agentDialogVisible = true
      this.agentDialogInfo = row
    }
  },
  mounted() {
    this.getTableData()
    // 6s重新获取一次
    this.getDataInterval = setInterval(this.getTableData, 6000)
  },
  destroyed() {
    clearInterval(this.getDataInterval)
  }
}
</script>

<style lang="scss" scoped>
  .order{
    margin: 15px;
    font-size: 14px;
    text-align: left;
  }
</style>

<style lang="scss">
.el-step__icon.is-text{
  width: 20px !important;
  height: 20px !important;
}
.el-step__title{
  font-size: 14px;
  line-height: 18px;
}

.order-page-list-item {
  padding:30px 0 20px 0;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgb(229, 231, 235) !important;
  background-color: white;
  .list-row-item {
    display: flex;
    justify-content: space-between;
    height: 28px;
    line-height: 28px;
    .row-item-left{
      color: rgb(64, 158, 255);
      &::before{
        content: "●";
        margin-right: 5px;
        color: rgb(64, 158, 255);
      }
    }
  }
}
.mini-tip{
    border-radius: 50%;
    z-index: 2;
    padding: 10px !important;
    left: 30px !important;
    right: inherit !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: rgb(204, 204, 204) 0px 1px 1px !important;
    border: none !important;
    width: 30px;
    height: 20px;
    line-height: 20px;
    background-color: rgb(103, 194, 58) !important;
    text-align: center;
    color: white;
    position: absolute;
    top: -20px;
  }

.card-right-index {
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255);
  line-height: 26px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  height: 26px;
  background: rgb(64, 158, 255);
  text-align: center;
  box-shadow: rgb(204, 204, 204) 0px 1px 1px;
  border-radius: 3px 3px 3px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .phone-index{
    background-color: #f56c6c !important;
    padding: 0 10px;
  }
}
.order-record-dialog {
  background-color: white;
}
.order-record-dialog.is-fullscreen {
  width: 50%;
  background-color: rgb(240, 248, 255) !important;
  .el-dialog__header{
    background-color: white;
  }
  
}
.order-agent-dialog{

  .el-table__row {
    background: #e1f3d8;
  }
}
.flex-align-center{
  position: relative;
  display: flex;
  align-items: center;
  line-height: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 4px 0px;
  padding: 0px;
  .left{
    width: 120px;
    text-align: justify;
    color: #909399;
    line-height: 0;
    &::after{
      content: "";
      width: 100%;
      color: #909399;
      display: inline-block;
      overflow: hidden;
      height: 0;
    }
  }
  .middle{
    color: #909399;
    margin: -6px 0 0 5px;
  }
  .right{
    margin-left: 15px;
    color: #409eff;
    font-weight: 600;
  }
}
</style>