import { render, staticRenderFns } from "./UserOrderView.vue?vue&type=template&id=683aebc4&scoped=true"
import script from "./UserOrderView.vue?vue&type=script&lang=js"
export * from "./UserOrderView.vue?vue&type=script&lang=js"
import style0 from "./UserOrderView.vue?vue&type=style&index=0&id=683aebc4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683aebc4",
  null
  
)

export default component.exports